export const bookingStatusFieldValues = [
  { value: 'new', text: 'New' },
  { value: 'pending', text: 'Pending' },
  { value: 'confirmed', text: 'Confirmed' },
  { value: 'canceled', text: 'Canceled' },
  { value: 'declined', text: 'Declined' },
  { value: 'expired', text: 'Expired' },
  { value: 'request', text: 'Request' },
  { value: 'ignore', text: 'Ignore' },
]

export const cancellationAmountTypeFieldValues = [
  { value: 'percentage', text: 'Percentage' },
  { value: 'flat', text: 'Flat' },
]

export const bookingSourceFieldValues = [
  { value: 'airbnb', text: 'AirBnB' },
  { value: 'booking', text: 'Booking.com' },
  { value: 'direct', text: 'Direct' },  
  { value: 'website', text: 'Website' },
  { value: 'homeaway', text: 'Homeaway' },
  { value: 'hotel', text: 'Hotel' },
  { value: 'google', text: 'Google' },
  { value: 'influencer', text: 'Influencer' },
  { value: 'personal', text: 'Personal' },
  { value: 'repairs', text: 'Repairs' },
  { value: 'self', text: 'Self' },
  { value: 'vrbo', text: 'VRBO' },
  { value: 'other', text: 'Other' },
]

const generateBookingSourceFieldValues = () => {
  const bookingSourceRef: {[v: string]: string} = {}
  bookingSourceFieldValues.forEach(bookingSource => {
    // if(bookingSource.value !== 'other') {
      bookingSourceRef[bookingSource.value] = bookingSource.text
    // }
  })
  return bookingSourceRef
}

export const bookingSourceFieldValuesRef = generateBookingSourceFieldValues()