import { Form, Formik } from "formik"
import { Alert, Button, Col, Row } from "react-bootstrap"
import { PropertyBookingEntry } from "../../../Types/bookingTypes"
import { CheckBoxInputField } from "../../FormFields/CheckBoxInput/CheckBoxInputField"
import { DateInputField } from "../../FormFields/DateInput/DateInputField"
import { DateRangeInputField } from "../../FormFields/DateInput/DateRangeInputField"
import { SelectInputField } from "../../FormFields/SelectInput/SelectInputField"
import { TextInputField } from "../../FormFields/TextInput/TextInputField"
import { bookingSourceFieldValues, bookingSourceFieldValuesRef, bookingStatusFieldValues, cancellationAmountTypeFieldValues } from "./BookingFormConstants"
import { blankBooking, bookingSchema } from "./BookingFormHelpers"
import timeService from "../../../services/time/timeService"
import { getChildProperties } from "../../../Helpers/Property/multiUnit"
import Collapsible from "../../Collapsible/Collapsible"
import canAccess from "../../../services/accessService/accessService"
import { ArrayFieldInput } from "../../FormFields/ArrayInput/ArrayFieldInput"
import { linkedServicesFieldValues } from "../commonFieldValues"
import { PhoneNumberInputField } from "../../FormFields/PhoneNumberInput/PhoneNumberInputField"
import { useState } from "react"

export interface PropertyBookingFormEntry extends PropertyBookingEntry {
  dateRange: any,
  bookingTotal: any,
}

export default function BookingForm(props: {
  disabled: boolean,
  onSubmit: (v: any) => Promise<any>,
  initialValues?: Partial<PropertyBookingEntry>,
  propertyOptionList: { value: string, text: string }[],
  propertyData: { [pId: string]: any },
  dateValidationFn?: (propertyId: string, checkIn: Date, checkOut: Date) => void,
  dateValidationStatus?: { available: boolean, message: string, status: string },
  quickBooking?: boolean,
}) {
  const {
    disabled,
    onSubmit,
    initialValues,
    propertyOptionList,
    propertyData,
    dateValidationFn,
    dateValidationStatus,
    quickBooking,
  } = props

  const [removeCleaningButtonActive, setRemoveCleaningButtonActive] = useState(true)

  const handleInitialValues = (bookingData: any) => {
    const {
      pricePerNight,
      checkIn,
      checkOut,
      bookingTotal,
      propertyId
    } = bookingData
    const data = {
      ...bookingData
    }
    if (!bookingTotal && pricePerNight) {
      const nights = timeService.calculateNights(checkIn, checkOut)
      const bookingTotal = Number((Number(pricePerNight) * nights).toFixed(2))
      data.bookingTotal = bookingTotal
    }

    if(checkIn) {
      data.checkIn = new Date(checkIn)
    }

    if(checkOut) {
      data.checkOut = new Date(checkOut)
    }

    if (propertyId && propertyData && propertyData[propertyId]) {
      const propertyInfo = propertyData[propertyId]
      // set cleaning price
      if (!data.cleaning) {
        const cleaningPrice = propertyInfo && propertyInfo.pricing && propertyInfo.pricing.cleaning ? Number(propertyInfo.pricing.cleaning) : 0
        data.cleaning = cleaningPrice
      }
      // set booking guest info
      if (!data.requiresBookingGuestInfo) {
        const requiresBookingGuestInfo = propertyInfo.services.bookingGuestInfo
        data.requiresBookingGuestInfo = requiresBookingGuestInfo
      }
      // check if requires confirmation
      if (!data.requiresConfirmation) {
        const isFontaine = propertyInfo.title.toLowerCase().indexOf('fontaine') > -1
        const isParentProperty = getChildProperties(propertyData, propertyInfo._id).length > 0
        const requiresConfirmation = isFontaine && !isParentProperty
        data.requiresConfirmation = requiresConfirmation
      }
    }
    delete data.quickBooking

    if (!data.source) {
      data.source = 'direct'
    }

    return data
  }

  const checkDateRange = async (propertyId: string, checkIn: Date, checkOut: Date) => {
    if (dateValidationFn && propertyId && checkIn && checkOut) {
      dateValidationFn(propertyId, checkIn, checkOut)
    }
  }

  return (
    <div className="booking-form">
      <Formik
        initialValues={{
          ...blankBooking(),
          ...(initialValues ? handleInitialValues(initialValues) : {})
        }}
        validationSchema={bookingSchema}
        // validate={values => { const errors: any = {}; return errors; }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const submissionResponse = await onSubmit(values)
            if (!submissionResponse.error) {
              return
            }
          } catch (err) {
            // custom error handler
            setSubmitting(false)
          }
          setSubmitting(false)
        }}
      >
        {({ values, errors, setFieldValue, isSubmitting }) => {
          const isFontaine = values.propertyId && propertyData[values.propertyId] && propertyData[values.propertyId].title.toLowerCase().indexOf('fontaine') > -1

          const showRemoveCleaningButton = values.bookingTotal && values.cleaning && removeCleaningButtonActive
          return (
            <Form>
              {!quickBooking && (
                <h3>General Info</h3>
              )}

              <DateRangeInputField
                fieldName={'dateRange'}
                fieldLabel={'Date Range'}
                startDate={values.checkIn}
                placeholder={'mm/dd/yyyy - mm/dd/yyyy'}
                endDate={values.checkOut}
                onChange={(dates: [Date | null, Date | null]) => {
                  if (!quickBooking) {
                    setFieldValue('bookingTotal', timeService.calculateBookingTotal(values.pricePerNight, dates[0], dates[1]))
                  }
                  // setFieldValue('pricePerNight', calculatePricePerNight(values.bookingTotal, dates[0], dates[1]))
                  const checkInVal = timeService.setDateTime(dates[0], { hour: 16 })
                  const checkOutVal = timeService.setDateTime(dates[1], { hour: 11 })
                  setFieldValue('checkIn', checkInVal)
                  setFieldValue('checkOut', checkOutVal)
                  if (checkInVal && checkOutVal) {
                    checkDateRange(values.propertyId, checkInVal, checkOutVal)
                  }
                }} />

              {!quickBooking && (
                <>
                  <DateInputField
                    fieldLabel={'Check In'}
                    placeholder={'mm/dd/yyyy'}
                    onChange={(date) => {
                      setFieldValue('bookingTotal', timeService.calculateBookingTotal(values.pricePerNight, date, values.checkOut))
                      checkDateRange(values.propertyId, values.checkIn, values.checkOut)
                    }}
                    maxDate={values.checkOut}
                    fieldName={'checkIn'} />

                  <DateInputField
                    fieldLabel={'Check Out'}
                    placeholder={'mm/dd/yyyy'}
                    onChange={(date) => {
                      setFieldValue('bookingTotal', timeService.calculateBookingTotal(values.pricePerNight, values.checkIn, date))
                      checkDateRange(values.propertyId, values.checkIn, values.checkOut)
                    }}
                    minDate={values.checkIn}
                    fieldName={'checkOut'} />
                </>
              )}


              <SelectInputField
                fieldLabel={'Property Id'}
                placeholder={'- Select Property -'}
                required={true}
                onChange={(e: any) => {
                  if (e.target.value && propertyData[e.target.value]) {
                    const propertyInfo = propertyData[e.target.value]

                    // set cleaning price
                    const cleaningPrice = propertyInfo && propertyInfo.pricing && propertyInfo.pricing.cleaning ? Number(propertyInfo.pricing.cleaning) : 0
                    setFieldValue('cleaning', cleaningPrice)

                    // set booking guest info
                    const requiresBookingGuestInfo = propertyInfo.services.bookingGuestInfo
                    setFieldValue('requiresBookingGuestInfo', requiresBookingGuestInfo)

                    // check if requires confirmation
                    const isFontaine = propertyInfo.title.toLowerCase().indexOf('fontaine') > -1
                    const isParentProperty = getChildProperties(propertyData, propertyInfo._id).length > 0
                    const requiresConfirmation = isFontaine && !isParentProperty
                    setFieldValue('requiresConfirmation', requiresConfirmation)

                    checkDateRange(e.target.value, values.checkIn, values.checkOut)
                  }
                }}
                options={propertyOptionList}
                fieldName={'propertyId'} />

              {dateValidationStatus && dateValidationStatus.status && values.checkIn && values.checkOut && values.propertyId && (
                <Alert variant={dateValidationStatus.status}>
                  {dateValidationStatus.message}
                </Alert>
              )}

              <hr />

              {!quickBooking && (
                <h3>Booking Contact Info</h3>
              )}

              <TextInputField
                fieldLabel={'First Name'}
                placeholder={'First Name'}
                fieldName={'contactInfo.firstName'} />

              <TextInputField
                fieldLabel={'Last Name'}
                placeholder={'Last Name'}
                fieldName={'contactInfo.lastName'} />

              {!quickBooking && (
                <>
                  <TextInputField
                    fieldLabel={'Email'}
                    fieldType={'email'}
                    placeholder={'Email'}
                    fieldName={'contactInfo.email'} />

                  {/* <TextInputField
                    fieldLabel={'Phone'}
                    placeholder={'Phone'}
                    fieldName={'contactInfo.phone'} /> */}

                  <PhoneNumberInputField
                    fieldLabel="Phone"
                    placeholder="Phone"
                    fieldName={`contactInfo.phone`} />
                </>
              )}

              <hr />

              {!quickBooking && (
                <>
                  <h3>Booking Guest Info</h3>

                  <CheckBoxInputField
                    fieldLabel={'Requires Booking Guest Info'}
                    fieldName={'requiresBookingGuestInfo'} />

                  <TextInputField
                    fieldLabel={'Booking Guest Info Id'}
                    placeholder={'Booking Guest Info Id'}
                    fieldName={'bookingGuestInfoId'} />

                  <hr />

                  <h3>Guest Counts</h3>

                  <TextInputField
                    fieldLabel={'Total Guest Count'}
                    fieldType={'number'}
                    placeholder={'Guest Count'}
                    fieldName={'guestInfo.guestTotal'} />

                  <TextInputField
                    fieldLabel={'Adults'}
                    fieldType={'number'}
                    placeholder={'Adults'}
                    fieldName={'guestInfo.adults'} />

                  <TextInputField
                    fieldLabel={'Children'}
                    fieldType={'number'}
                    placeholder={'Children'}
                    fieldName={'guestInfo.children'} />

                  <TextInputField
                    fieldLabel={'Infants'}
                    fieldType={'number'}
                    placeholder={'Infants'}
                    fieldName={'guestInfo.infants'} />

                  <hr />
                </>
              )}


              {!quickBooking && (
                <h3>Booking Details</h3>
              )}


              <Row>
                <Col>
                  <TextInputField
                    fieldLabel={'Booking Total'}
                    fieldType={'number'}
                    disabled={!values.checkIn || !values.checkOut}
                    onChange={(e) => {
                      setFieldValue('pricePerNight', timeService.calculatePricePerNight(e.target.value, values.checkIn, values.checkOut))
                      setRemoveCleaningButtonActive(true)
                    }}
                    placeholder={'Booking Total'}
                    fieldName={'bookingTotal'} />
                </Col>
                {showRemoveCleaningButton && (
                  <Col xs="auto">
                    <Button
                      className="my-2"
                      variant={'secondary'}
                      onClick={() => {
                        setRemoveCleaningButtonActive(false)
                        const newTotal = values.bookingTotal - values.cleaning > 0 ? values.bookingTotal - values.cleaning : 0
                        setFieldValue('bookingTotal', newTotal)
                        setFieldValue('pricePerNight', timeService.calculatePricePerNight(newTotal, values.checkIn, values.checkOut))
                      }}
                    >
                      Remove Cleaning (${values.cleaning})
                    </Button>
                  </Col>
                )}
              </Row>

              <hr />

              {quickBooking && (
                <>
                  <Collapsible
                    openText="Show Extra Options"
                    closeText="Hide Extra Options"
                  >
                    <>
                      <TextInputField
                        fieldLabel={'Price Per Night'}
                        fieldType={'number'}
                        disabled
                        placeholder={'Price Per Night'}
                        fieldName={'pricePerNight'} />

                      <TextInputField
                        fieldLabel={'Cleaning'}
                        fieldType={'number'}
                        placeholder={'Cleaning'}
                        fieldName={'cleaning'} />

                      {values.source === '' || bookingSourceFieldValuesRef[values.source] ? (
                        <SelectInputField
                          fieldLabel={'Booking Source'}
                          placeholder={'- Select Booking Source -'}
                          options={bookingSourceFieldValues}
                          fieldName={'source'} />
                      ) : (
                        <TextInputField
                          fieldLabel={'Booking Source'}
                          placeholder={'Booking Source'}
                          fieldName={'source'} />
                      )}

                      <TextInputField
                        fieldLabel={'Booking Source Confirmation Code'}
                        placeholder={'Booking Source Confirmation Code'}
                        fieldName={'sourceConfirmation'} />

                      <hr />

                      <h5>Confirmation</h5>
                      {canAccess('booking', 'form.requiresConfirmation') && isFontaine && (
                        <>
                          <CheckBoxInputField
                            fieldLabel={'Requires Confirmation'}
                            fieldName={'requiresConfirmation'} />

                          <TextInputField
                            fieldLabel={'Confirmation Code'}
                            placeholder={'Confirmation Code'}
                            fieldName={'confirmationCode'} />
                        </>
                      )}
                      <SelectInputField
                        fieldLabel={'Booking Status'}
                        placeholder={'- Select Booking Status -'}
                        required={true}
                        options={bookingStatusFieldValues}
                        fieldName={'bookingStatus'} />

                    </>
                  </Collapsible>
                </>
              )}

              {!quickBooking && (
                <>
                  <TextInputField
                    fieldLabel={'Price Per Night'}
                    fieldType={'number'}
                    disabled
                    placeholder={'Price Per Night'}
                    fieldName={'pricePerNight'} />

                  <TextInputField
                    fieldLabel={'Cleaning'}
                    fieldType={'number'}
                    placeholder={'Cleaning'}
                    fieldName={'cleaning'} />

                  {values.source === '' || bookingSourceFieldValuesRef[values.source] ? (
                    <SelectInputField
                      fieldLabel={'Booking Source'}
                      placeholder={'- Select Booking Source -'}
                      options={bookingSourceFieldValues}
                      fieldName={'source'} />
                  ) : (
                    <TextInputField
                      fieldLabel={'Booking Source'}
                      placeholder={'Booking Source'}
                      fieldName={'source'} />
                  )}

                  <TextInputField
                    fieldLabel={'Booking Source Confirmation Code'}
                    placeholder={'Booking Source Confirmation Code'}
                    fieldName={'sourceConfirmation'} />

                  <hr />
                  <h3>Confirmation</h3>

                  {canAccess('booking', 'form.requiresConfirmation') && isFontaine && (
                    <>
                      <CheckBoxInputField
                        fieldLabel={'Requires Confirmation'}
                        fieldName={'requiresConfirmation'} />

                      <TextInputField
                        fieldLabel={'Confirmation Code'}
                        placeholder={'Confirmation Code'}
                        fieldName={'confirmationCode'} />
                    </>
                  )}

                  <SelectInputField
                    fieldLabel={'Booking Status'}
                    placeholder={'- Select Booking Status -'}
                    required={true}
                    options={bookingStatusFieldValues}
                    fieldName={'bookingStatus'} />

                </>
              )}


              {!quickBooking && (
                <>
                  <hr />
                  <h3>Cancellation Policy</h3>

                  <TextInputField
                    fieldLabel={'Cancellation Amount'}
                    fieldType={'number'}
                    placeholder={'Cancellation Amount'}
                    fieldName={'cancellation.amount'} />

                  <SelectInputField
                    fieldLabel={'Cancellation Type'}
                    placeholder={'- Select Type -'}
                    required={true}
                    options={cancellationAmountTypeFieldValues}
                    fieldName={'cancellation.amountType'} />

                  <hr />

                  <h3>Booking Extra Info</h3>

                  <TextInputField
                    fieldLabel={'Notes'}
                    fieldType={'textarea'}
                    placeholder={'Notes'}
                    fieldName={'notes'} />

                  <DateInputField
                    fieldLabel={'Date Booking was Made'}
                    placeholder={'mm/dd/yyyy'}
                    fieldName={'dateBooked'} />

                  <DateInputField
                    fieldLabel={'Date Booking was Inquired'}
                    placeholder={'mm/dd/yyyy'}
                    fieldName={'dateInquired'} />

                  <Collapsible
                    title="Linked Services"
                    TitleTag="h3"
                  >
                    <div>
                      <label htmlFor="Linked Services">Linked Services</label><br />

                      <ArrayFieldInput
                        fieldName={'linkedServices'}
                        values={values.linkedServices}
                        setFieldValue={setFieldValue}
                        blankEntryFn={() => ({ linkedService: '', linkedServiceId: '' })}
                        fields={linkedServicesFieldValues} />
                    </div>
                  </Collapsible>

                </>
              )}


              {Object.keys(errors).length > 0 && (
                <>
                  Errors: {JSON.stringify(errors)}
                  <br />
                </>
              )}
              <Button className="mb-3" type="submit" disabled={Object.keys(errors).length > 0 || isSubmitting || disabled}>
                Submit
              </Button>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
