import { FieldGeneratorFieldType } from "../../FormFields/FieldGenerator/FieldGenerator"
import { userFieldRoles } from "../EditUserForm/EditUserFormHelpers"


export const aliasFieldValues: FieldGeneratorFieldType[] = [
  {
    fieldType: 'text',
    fieldLabel: 'Alias',
    placeholder: 'Alias',
    fieldName: 'alias'
  },
  {
    fieldType: 'text',
    fieldLabel: 'Alias Source',
    placeholder: 'Alias Source',
    fieldName: 'aliasSource'
  },
]

export const calendarFieldValues: FieldGeneratorFieldType[] = [
  {
    fieldType: 'text',
    fieldLabel: 'Calendar Url',
    placeholder: 'Calendar Url',
    fieldName: 'url'
  },
  {
    fieldType: 'select',
    fieldLabel: 'Calendar Source',
    placeholder: '- Select Calendar Source -',
    fieldName: 'source',
    options: [
      { value: 'airbnb', text: 'AirBnB' },
      { value: 'homeaway', text: 'Homeaway' },
      { value: 'booking', text: 'Booking.com' },
      { value: 'rentalsUnited', text: 'Rentals United' },
      { value: 'internal', text: 'BenichayBrothers.com' },
    ]
  },
]

export const listedFieldValues: FieldGeneratorFieldType[] = [
  {
    fieldType: 'text',
    fieldLabel: 'Listed Url',
    placeholder: 'Listed Url',
    fieldName: 'url'
  },
  {
    fieldType: 'select',
    fieldLabel: 'Listed Source',
    placeholder: '- Select Listed Source -',
    fieldName: 'source',
    options: [
      { value: 'airbnb', text: 'AirBnB' },
      { value: 'homeaway', text: 'Homeaway' },
      { value: 'booking', text: 'Booking.com' },
    ]
  },
  {
    fieldType: 'text',
    fieldLabel: 'Internal Name',
    placeholder: 'Internal Name',
    fieldName: 'internalName'
  },
  {
    fieldType: 'text',
    fieldLabel: 'Internal Title',
    placeholder: 'Internal Title',
    fieldName: 'internalTitle'
  },
]

export const aboutSectionsFieldValues: FieldGeneratorFieldType[] = [
  {
    fieldType: 'text',
    fieldLabel: 'Section Title',
    placeholder: 'Section Title',
    fieldName: 'sectionTitle'
  },
  {
    fieldType: 'textarea',
    fieldLabel: 'Section Content',
    placeholder: 'Section Content',
    fieldName: 'content'
  },
  {
    fieldType: 'text',
    fieldLabel: 'sectionId',
    placeholder: 'sectionId',
    fieldName: 'sectionId'
  }
]

export const extraFeesFieldValues: FieldGeneratorFieldType[] = [
  {
    fieldType: 'text',
    fieldLabel: 'Fee ID',
    placeholder: 'Fee ID',
    fieldName: 'feeId'
  },
  {
    fieldType: 'number',
    fieldLabel: 'Extra Fee Amount',
    placeholder: 'Extra Fee Amount',
    fieldName: 'amount'
  },
  {
    fieldType: 'select',
    fieldLabel: '- Select Fee Frequency -',
    placeholder: 'Fee Frequency',
    fieldName: 'frequency',
    options: [
      { value: 'once', text: 'Once' },
      { value: 'nightly', text: 'Nightly' },
    ]
  },
  {
    fieldType: 'text',
    fieldLabel: 'Fee Title',
    placeholder: 'Fee Title',
    fieldName: 'title'
  },
  {
    fieldType: 'textarea',
    fieldLabel: 'Fee Description',
    placeholder: 'Fee Description',
    fieldName: 'description'
  }
]

export const authorizedUsersFieldValues = (): FieldGeneratorFieldType[] => ([
  {
    fieldType: 'text',
    fieldLabel: 'Email',
    required: true,
    placeholder: 'Email',
    fieldName: 'email'
  },
  {
    fieldType: 'select',
    fieldLabel: 'Role',
    placeholder: '- Select Role -',
    required: true,
    fieldName: 'role',
    options: userFieldRoles()
  },
])


export const fontainePortalLinkedService = () => {
  return {
    linkedService: 'fontainePortal',
    linkedServiceId: JSON.stringify({
      "username": "USERNAME",
      "password": "PASSWORD",
      "email": "EMAIL",
      "phone": "PHONE",
      "skipValidation": true
    })
  }
}

export const earlyCheckInLinkedService = () => {
  return {
    linkedService: 'earlyCheckIn',
    linkedServiceId: JSON.stringify({
      enabled: true,
      options: []
    })
  }
}

export const lateCheckOutLinkedService = () => {
  return {
    linkedService: 'lateCheckOut',
    linkedServiceId: JSON.stringify({
      enabled: true,
      options: []
    })
  }
}

export const remoteLockLinkedService = () => {
  return {
    linkedService: 'remoteLock',
    linkedServiceId: JSON.stringify({
      deviceId: 'DEVICE_ID',
      deviceName: 'DEVICE_NAME',
      deviceType: 'locks'
    })
  }
}

export const parkingLinkedService = () => {
  return {
    linkedService: 'parking',
    linkedServiceId: JSON.stringify({
      parkingType: 'PARKING_TYPE',
      parkingGroups: ['PARKING_LOCATION'],
      parkingCost: 35,
    })
  }
}

export const hotelCleaningFee = () => {
  return {
    feeId: 'hotelCleaning',
    amount: 205,
    frequency: 'once',
    title: 'Hotel Cleaning',
    description: 'Cleaning fee for hotel stay + tax'
  }
}

export const hotelDepositFee = () => {
  return {
    feeId: 'hotelDeposit',
    amount: 250,
    frequency: 'nightly',
    title: 'Hotel Deposit',
    description: 'Deposit fee per night'
  }
}

export const hotelResortFee = () => {
  return {
    feeId: 'hotelResort',
    amount: 16.52,
    frequency: 'once',
    title: 'Hotel Resort',
    description: 'Resort fee for hotel stay'
  }
}

export const hourSelection = [
  { value: 0, text: '0' },
  { value: 1, text: '1' },
  { value: 2, text: '2' },
  { value: 3, text: '3' },
  { value: 4, text: '4' },
  { value: 5, text: '5' },
  { value: 6, text: '6' },
  { value: 7, text: '7' },
  { value: 8, text: '8' },
  { value: 9, text: '9' },
  { value: 10, text: '10' },
  { value: 11, text: '11' },
  { value: 12, text: '12' },
  { value: 13, text: '13' },
  { value: 14, text: '14' },
  { value: 15, text: '15' },
  { value: 16, text: '16' },
  { value: 17, text: '17' },
  { value: 18, text: '18' },
  { value: 19, text: '19' },
  { value: 20, text: '20' },
  { value: 21, text: '21' },
  { value: 22, text: '22' },
  { value: 23, text: '23' },
]

export const minuteSelection = [
  { value: 0, text: '00' },
  { value: 15, text: '15' },
  { value: 30, text: '30' },
  { value: 45, text: '45' },
]
