import { useMemo, useState } from "react";
import { Badge, Button, Col, Collapse, Container, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FieldGeneratorFieldType } from "../../../Components/FormFields/FieldGenerator/FieldGenerator";
import PropertyTaskForm from "../../../Components/Forms/PropertyTaskForm/PropertyTaskForm";
import QuickForm from "../../../Components/Forms/QuickForm/QuickForm";
import PropertyTaskEntry from "../../../Components/PropertyTasks/PropertyTaskEntry";
import PropertyTaskEntryView from "../../../Components/PropertyTasks/PropertyTaskEntryView";
import tasksByPropertyHelper from "../../../Helpers/PropertyTasks/TasksByPropertyHelper";
import { textBooleanHandler } from "../../../Helpers/ValueHandlers/boolHandler";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import useMyPropertiesSelectValues from "../../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook";
import useOccupancyRef from "../../../Hooks/UseOccupancyRef/UseOccupancyRef";
import useUploadImage from "../../../Hooks/UseUploadImage/useUploadImageHook";
import useUserRefs from "../../../Hooks/UseUsersQuery/UseUserRefs";
import useUsersSelectValues from "../../../Hooks/UseUsersQuery/UseUsersSelectValues";
import { accountSelector } from "../../../redux/account/accountSlice";
import { PropertyTaskType, WorkLogEntryType } from "../../../Types/propertyTaskType";
import './propertyTaskListPage.scss'
import { useAddNewPropertyTaskMutation, useEditPropertyTaskMutation, useGetPropertyTasksQuery } from "../../../services/bClientApi";
import canAccess from "../../../services/accessService/accessService";

export default function PropertyTaskListPage() {

    const [
        addNewPropertyTask,
        {
            isLoading: addNewPropertyTaskLoading,
            error: addNewPropertyTaskError,
        }
    ] = useAddNewPropertyTaskMutation()

    const [
        editPropertyTask,
        {
            isLoading: editPropertyTaskLoading,
            error: editPropertyTaskError,
        }
    ] = useEditPropertyTaskMutation()

    const HIDE_BUTTONS = {
        view: true,
        delete: true,
    }

    const userRef = useUserRefs()
    const userSelectOptions = useUsersSelectValues(userRef)

    const { myProperties, publicPropertyIdKeys } = useMyProperties()
    const myPropertySelectOptions = useMyPropertiesSelectValues(myProperties)

    const { email, role } = useSelector(accountSelector)

    const {
        imageUploadProgress,
        uploadImageFn,
        uploadImageMultiFn,
        error: uploadImageError,
    } = useUploadImage()

    const propertyOccupancyRef = useOccupancyRef({ myProperties })

    const [taskModalSettings, setTaskModalSettings] = useState<any>({
        show: false,
    })

    const [generalFilters, setGeneralFilters] = useState<any>({
        cleaning: false,
        maintenance: false,
        myTasks: false,
        hideOccupied: false,
    })

    const [openSections, setOpenSections] = useState<any>({})

    const [
        activeQuery,
        // setActiveQuery
    ] = useState<any>({
        query: {
            notStatus: ['confirmed'],
            // status: ['new', 'approved', 'in progress', 'complete', 'confirmed'],
        },
        limit: 500,
        page: 1,
    })

    const {
        data,
        error,
        isLoading,
    } = useGetPropertyTasksQuery({
        propertyTasksQuery: {
            ...activeQuery.query,
            propertyId: publicPropertyIdKeys,
        },
        pagination: {
            page: activeQuery.page,
            limit: activeQuery.limit,
        }
    }, { skip: publicPropertyIdKeys.length === 0 })
    const propertyTasksList = data?.docs || []

    const toggleOpenSection = (sectionName: string) => {
        const newOpenSections = { ...openSections }
        if (newOpenSections[sectionName]) {
            delete newOpenSections[sectionName]
        } else {
            newOpenSections[sectionName] = true
        }
        setOpenSections(newOpenSections)
    }

    const clearTaskModal = () => {
        setTaskModalSettings({
            show: false,
        })
    }

    const handleQuickView = (taskInfo: PropertyTaskType) => {
        setTaskModalSettings({
            show: true,
            quickView: taskInfo._id,
            taskInfo: { ...taskInfo },
        })
    }

    const CUSTOM_BUTTONS = [
        {
            text: 'Quick View',
            onClick: handleQuickView
        }
    ]

    const generalFilteredTaskList = createGeneralFilteredTaskList(propertyTasksList, propertyOccupancyRef, generalFilters, email)

    const newTasks = useMemo(() => {
        const newTaskList: PropertyTaskType[] = []
        if (generalFilteredTaskList) {
            generalFilteredTaskList.forEach((task: PropertyTaskType) => {
                if (task.status === 'new') {
                    newTaskList.push(task)
                }
            })
        }
        return newTaskList
    }, [generalFilteredTaskList])

    const completeTasks = useMemo(() => {
        const completeTaskList: PropertyTaskType[] = []
        if (generalFilteredTaskList) {
            generalFilteredTaskList.forEach((task: PropertyTaskType) => {
                if (task.status === 'complete') {
                    completeTaskList.push(task)
                }
            })
        }
        return completeTaskList
    }, [generalFilteredTaskList])

    const priorityTasks = useMemo(() => {
        const priorityTaskList: PropertyTaskType[] = []
        if (generalFilteredTaskList) {
            generalFilteredTaskList.forEach((task: PropertyTaskType) => {
                // add prioirity value less than 3
                if (task.priority && task.priority < 3 && task.status !== 'complete') {
                    priorityTaskList.push(task)
                }
            })
        }
        return priorityTaskList
    }, [generalFilteredTaskList])

    const inProgressTasks = useMemo(() => {
        const inProgressTaskList: PropertyTaskType[] = []
        if (generalFilteredTaskList) {
            generalFilteredTaskList.forEach((task: PropertyTaskType) => {
                if (task.status === 'in progress') {
                    inProgressTaskList.push(task)
                }
            })
        }
        return inProgressTaskList
    }, [generalFilteredTaskList])

    const buildingTasks = useMemo(() => {
        const buildingTaskList: PropertyTaskType[] = []
        if (generalFilteredTaskList) {
            generalFilteredTaskList.forEach((task: PropertyTaskType) => {
                // check tags for tag.tag === building
                if (task.tags && task.status !== 'complete') {
                    task.tags.forEach((tag: any) => {
                        if (tag.tag === 'building') {
                            buildingTaskList.push(task)
                        }
                    })
                }
            })
        }
        return buildingTaskList
    }, [generalFilteredTaskList])

    const tasksByProperty = useMemo(() => {
        const filteredList = generalFilteredTaskList.filter((task: PropertyTaskType) => {
            return task.status !== 'complete'
        })
        return tasksByPropertyHelper(filteredList, myProperties)
    }, [generalFilteredTaskList, myProperties])

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Property Task List</h1>
                    {error && (<>{JSON.stringify(error)}<br /></>)}
                    {addNewPropertyTaskError && (<>{JSON.stringify(addNewPropertyTaskError)}<br /></>)}
                    {editPropertyTaskError && (<>{JSON.stringify(editPropertyTaskError)}<br /></>)}

                    <hr />

                    <Button
                        size="sm"
                        onClick={() => {
                            setTaskModalSettings({
                                show: true,
                                quickTask: true,
                            })
                        }}
                        className="mb-2 me-2">
                        Quick Task
                    </Button>

                    <Button
                        size="sm"
                        variant="secondary"
                        onClick={() => {
                            setOpenSections(!openSections.all ? { all: true } : {})
                        }}
                        className="mb-2 me-2">
                        {!openSections.all ? 'Expand' : 'Close'} All
                    </Button>

                    <QuickForm
                        initialData={{
                            // cleaning: false,
                            // maintenance: false,
                            myTasks: false,
                            hideOccupied: false,
                        }}
                        fields={TASK_LIST_FILTER_FIELDS}
                        onChange={(data: any) => {
                            const fieldName = data.target.name
                            const fieldValue = data.target.value
                            if (quickFilterFields[fieldName]) {
                                const newFilters = {
                                    ...generalFilters,
                                    [fieldName]: quickFilterFields[fieldName].handler ? quickFilterFields[fieldName].handler(fieldValue) : fieldValue,
                                }
                                setGeneralFilters(newFilters)
                            }
                        }}
                    />

                    <hr />

                    {newTasks && (
                        <div className="task-list-section">
                            <Row className="task-list-section-title">
                                <Col>
                                    <h3>
                                        New Tasks Pending Approval
                                        <Badge className="ms-2">
                                            {newTasks.length}
                                        </Badge>
                                    </h3>
                                </Col>
                                {!openSections.all && (
                                    <Col xs="auto">
                                        <Button
                                            className="mr-2 mb-2 me-3"
                                            variant="secondary"
                                            size="sm"
                                            onClick={() => toggleOpenSection('new')}>
                                            {openSections['new'] ? 'Collapse' : 'Expand'}
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                            <Collapse in={openSections['new'] || openSections.all}>
                                <div className="task-list-entries">
                                    {newTasks.map((task: PropertyTaskType, idx: number) => (
                                        <PropertyTaskEntry
                                            key={`new-${idx}`}
                                            taskInfo={task}
                                            propertyInfo={myProperties[task.propertyId]}
                                            occupancyInfo={propertyOccupancyRef[task.propertyId]}
                                            userRef={userRef}
                                            hideButtons={HIDE_BUTTONS}
                                            customButtons={CUSTOM_BUTTONS}
                                        />
                                    ))}
                                </div>
                            </Collapse>
                            <hr />
                        </div>
                    )}

                    {completeTasks && (
                        <div className="task-list-section">
                            <Row className="task-list-section-title">
                                <Col>
                                    <h3>
                                        Completed Tasks
                                        <Badge className="ms-2">
                                            {completeTasks.length}
                                        </Badge>
                                    </h3>
                                </Col>
                                {!openSections.all && (
                                    <Col xs="auto">
                                        <Button
                                            className="mr-2 mb-2 me-3"
                                            variant="secondary"
                                            size="sm"
                                            onClick={() => toggleOpenSection('complete')}>
                                            {openSections['complete'] ? 'Collapse' : 'Expand'}
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                            <Collapse in={openSections['complete'] || openSections.all}>
                                <div className="task-list-entries">
                                    {completeTasks.map((task: PropertyTaskType, idx: number) => (
                                        <PropertyTaskEntry
                                            key={`complete-${idx}`}
                                            taskInfo={task}
                                            propertyInfo={myProperties[task.propertyId]}
                                            occupancyInfo={propertyOccupancyRef[task.propertyId]}
                                            userRef={userRef}
                                            hideButtons={HIDE_BUTTONS}
                                            customButtons={CUSTOM_BUTTONS}
                                        />
                                    ))}
                                </div>
                            </Collapse>
                            <hr />
                        </div>
                    )}

                    {priorityTasks && (
                        <div className="task-list-section">
                            <Row className="task-list-section-title">
                                <Col>
                                    <h3>
                                        Priority Tasks
                                        <Badge className="ms-2">
                                            {priorityTasks.length}
                                        </Badge>
                                    </h3>
                                </Col>
                                {!openSections.all && (
                                    <Col xs="auto">
                                        <Button
                                            className="mr-2 mb-2 me-3"
                                            variant="secondary"
                                            size="sm"
                                            onClick={() => toggleOpenSection('priority')}>
                                            {openSections['priority'] ? 'Collapse' : 'Expand'}
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                            <Collapse in={openSections['priority'] || openSections.all}>
                                <div className="task-list-entries">
                                    {priorityTasks.map((task: PropertyTaskType, idx: number) => (
                                        <PropertyTaskEntry
                                            key={`priority-${idx}`}
                                            taskInfo={task}
                                            propertyInfo={myProperties[task.propertyId]}
                                            occupancyInfo={propertyOccupancyRef[task.propertyId]}
                                            userRef={userRef}
                                            hideButtons={HIDE_BUTTONS}
                                            customButtons={CUSTOM_BUTTONS}
                                        />
                                    ))}
                                </div>
                            </Collapse>
                            <hr />
                        </div>
                    )}

                    {inProgressTasks && (
                        <div className="task-list-section">
                            <Row className="task-list-section-title">
                                <Col>
                                    <h3>
                                        In Progress Tasks
                                        <Badge className="ms-2">
                                            {inProgressTasks.length}
                                        </Badge>
                                    </h3>
                                </Col>
                                {!openSections.all && (
                                    <Col xs="auto">
                                        <Button
                                            className="mr-2 mb-2 me-3"
                                            variant="secondary"
                                            size="sm"
                                            onClick={() => toggleOpenSection('inProgress')}>
                                            {openSections['inProgress'] ? 'Collapse' : 'Expand'}
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                            <Collapse in={openSections['inProgress'] || openSections.all}>
                                <div className="task-list-entries">
                                    {inProgressTasks.map((task: PropertyTaskType, idx: number) => (
                                        <PropertyTaskEntry
                                            key={`inProgress-${idx}`}
                                            taskInfo={task}
                                            propertyInfo={myProperties[task.propertyId]}
                                            occupancyInfo={propertyOccupancyRef[task.propertyId]}
                                            userRef={userRef}
                                            hideButtons={HIDE_BUTTONS}
                                            customButtons={CUSTOM_BUTTONS}
                                        />
                                    ))}
                                </div>
                            </Collapse>
                            <hr />
                        </div>
                    )}

                    {buildingTasks && (
                        <div className="task-list-section">
                            <Row className="task-list-section-title">
                                <Col>
                                    <h3>
                                        Building Tasks
                                        <Badge className="ms-2">
                                            {buildingTasks.length}
                                        </Badge>
                                    </h3>
                                </Col>
                                {!openSections.all && (
                                    <Col xs="auto">
                                        <Button
                                            className="mr-2 mb-2 me-3"
                                            variant="secondary"
                                            size="sm"
                                            onClick={() => toggleOpenSection('building')}>
                                            {openSections['building'] ? 'Collapse' : 'Expand'}
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                            <Collapse in={openSections['building'] || openSections.all}>
                                <div className="task-list-entries">
                                    {buildingTasks.map((task: PropertyTaskType, idx: number) => (
                                        <PropertyTaskEntry
                                            key={`building-${idx}`}
                                            taskInfo={task}
                                            propertyInfo={myProperties[task.propertyId]}
                                            occupancyInfo={propertyOccupancyRef[task.propertyId]}
                                            userRef={userRef}
                                            hideButtons={HIDE_BUTTONS}
                                            customButtons={CUSTOM_BUTTONS}
                                        />
                                    ))}
                                </div>
                            </Collapse>
                            <hr />
                        </div>
                    )}

                    {tasksByProperty && tasksByProperty.map((propertyTasks: {
                        propertyId: string,
                        shortTitle: string,
                        tasks: PropertyTaskType[]
                    }, idx: number) => {
                        const propertyInfo = myProperties[propertyTasks.propertyId];
                        const occupancyInfo = propertyOccupancyRef[propertyTasks.propertyId];
                        return (
                            <div className="task-list-section" key={`property-${propertyTasks.propertyId}-${idx}`}>
                                <Row className="task-list-section-title">
                                    <Col>
                                        <h5 className="pt-2">
                                            {propertyTasks.shortTitle}
                                            <Badge
                                                bg={occupancyInfo ? occupancyInfo.occupied ? 'danger' : 'success' : 'info'}
                                                className={`ms-2 small`}>
                                                {propertyTasks.tasks.length}
                                            </Badge>
                                            {/* {occupancyInfo && (
                                                <Badge
                                                    bg={occupancyInfo.occupied ? 'danger' : 'success'}
                                                    className="ms-2 mb-2 me-2">
                                                    {occupancyInfo.occupied ? 'occupied' : 'vacant'}
                                                </Badge>
                                            )} */}
                                        </h5>
                                        {propertyInfo && canAccess('routes', 'property-tasks') && (openSections[propertyTasks.propertyId] || openSections.all) && (
                                            <Link className="mx-2 mb-2" to={`/property-tasks?query={"propertyId":"${propertyInfo._id}"}`}>
                                                view all
                                            </Link>
                                        )}
                                    </Col>
                                    {!openSections.all && (
                                        <Col xs="auto">
                                            <Button
                                                className="mr-2 mb-2 me-3"
                                                variant="secondary"
                                                size="sm"
                                                onClick={() => toggleOpenSection(propertyTasks.propertyId)}>
                                                {openSections[propertyTasks.propertyId] ? 'Collapse' : 'Expand'}
                                            </Button>
                                        </Col>
                                    )}


                                </Row>
                                {/* {propertyInfo && (
                                    <a target="_blank" href={`${googleAddress(propertyInfo.address)}`}>
                                        {formatAddress(propertyInfo.address)}
                                    </a>
                                )} */}
                                <Collapse in={openSections[propertyTasks.propertyId] || openSections.all}>
                                    <div className="task-list-entries">
                                        {propertyTasks.tasks.map((task: PropertyTaskType, jdx: number) => (
                                            <PropertyTaskEntry
                                                key={`property-${propertyTasks.propertyId}-task-${jdx}`}
                                                taskInfo={task}
                                                propertyInfo={propertyInfo}
                                                occupancyInfo={occupancyInfo}
                                                userRef={userRef}
                                                hideButtons={HIDE_BUTTONS}
                                                customButtons={CUSTOM_BUTTONS}
                                            />
                                        ))}
                                    </div>
                                </Collapse>
                                <hr />
                            </div>
                        )
                    })}

                </Col>
            </Row>

            <Modal
                size={'lg'}
                show={taskModalSettings.show}
                onHide={clearTaskModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Task Modal</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {taskModalSettings.quickView && (
                        <PropertyTaskEntryView
                            taskInfo={taskModalSettings.taskInfo}
                            userRef={userRef}
                            propertyInfo={myProperties[taskModalSettings.taskInfo.propertyId]}
                            username={email}
                            occupancyInfo={propertyOccupancyRef[taskModalSettings.taskInfo.propertyId]}
                            disabled={isLoading || editPropertyTaskLoading}
                            role={role}
                            updateTask={async (updateTaskPayload: {
                                _id: string,
                                propertyTaskInfo: Partial<PropertyTaskType>
                            }) => {
                                const {
                                    propertyTaskInfo
                                } = updateTaskPayload
                                const updatedTask = await editPropertyTask({
                                    propertyTaskId: updateTaskPayload._id,
                                    propertyTaskInfo
                                }).unwrap()
                                if (
                                    propertyTaskInfo.progressPics ||
                                    propertyTaskInfo.workLog
                                ) {
                                    setTaskModalSettings({
                                        ...taskModalSettings,
                                        taskInfo: { ...updatedTask },
                                    })
                                } else {
                                    clearTaskModal()
                                }

                            }}
                            imageUploadProgress={imageUploadProgress}
                            uploadImageMultiFn={uploadImageMultiFn}
                        />
                    )}

                    {email && role && taskModalSettings.quickTask && (
                        <PropertyTaskForm
                            initialValues={{
                                createdBy: email,
                                status: role === 'admin' || role.indexOf('lead') > -1 ? 'approved' : 'new',
                                tags: [{ tag: 'maintenance' }]
                            }}
                            quickTask={true}
                            disabled={addNewPropertyTaskLoading}
                            onSubmit={async (values) => {
                                const newTask = await addNewPropertyTask(values).unwrap()
                                setTaskModalSettings({ show: false })
                                return newTask
                            }}
                            propertyOptionList={myPropertySelectOptions}
                            userSelectOptions={userSelectOptions}
                            uploadImageFn={uploadImageFn}
                            imageUploadProgress={imageUploadProgress}
                            uploadImageMultiFn={uploadImageMultiFn}
                        />
                    )}

                    {uploadImageError && (
                        <div className="alert alert-danger">
                            uploadImageError
                        </div>
                    )}


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={clearTaskModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </Container >
    )
}

const boolHandler = {
    handler: textBooleanHandler
}

const quickFilterFields: any = {
    cleaning: boolHandler,
    maintenance: boolHandler,
    myTasks: boolHandler,
    hideOccupied: boolHandler,
}

const TASK_LIST_FILTER_FIELDS: FieldGeneratorFieldType[] = [
    // {
    //     fieldName: 'cleaning',
    //     fieldType: 'checkbox',
    //     fieldLabel: 'Cleaning',
    // },
    // {
    //     fieldName: 'maintenance',
    //     fieldType: 'checkbox',
    //     fieldLabel: 'Maintenance',
    // },
    {
        fieldName: 'myTasks',
        fieldType: 'checkbox',
        fieldLabel: 'My Tasks',
    },
    {
        fieldName: 'hideOccupied',
        fieldType: 'checkbox',
        fieldLabel: 'Hide Occupied',
    },
]

const createGeneralFilteredTaskList = (propertyTasksList: PropertyTaskType[], propertyOccupancyRef: any, generalFilters: any, email?: string) => {
    const filteredTaskList: PropertyTaskType[] = []
    const activeFilters = Object.keys(generalFilters).filter((filter: string) => {
        return generalFilters[filter]
    })
    if (propertyTasksList) {
        if (activeFilters.length === 0) {
            return propertyTasksList
        }

        const {
            cleaning,
            maintenance,
            myTasks,
            hideOccupied,
        } = generalFilters

        propertyTasksList.forEach((task: PropertyTaskType) => {

            if (hideOccupied && propertyOccupancyRef[task.propertyId] && propertyOccupancyRef[task.propertyId].occupied === true) {
                return
            }

            if (
                myTasks && (task.assignedTo !== email ||
                    task.workLog.filter((log: WorkLogEntryType) => log.person === email).length === 0)
            ) {
                return
            }

            if (!cleaning && !maintenance) {
                filteredTaskList.push(task)
                return
            }

            const tagHash: { [v: string]: boolean } = {}
            task.tags.forEach((tag: { tag: string }) => {
                tagHash[tag.tag] = true
            })
            if (cleaning && tagHash.cleaning) {
                filteredTaskList.push(task)
                return
            }
            if (maintenance && tagHash.maintenance) {
                filteredTaskList.push(task)
                return
            }
        })

    }
    return filteredTaskList
}