import { Card } from "react-bootstrap"
import { Link } from "react-router-dom"
import timeService from "../../services/time/timeService"
import { PropertyBookingMessageType } from "../../Types/bookingMessageType"
import { PropertyType } from "../../Types/propertyTypes"
import { ReplyFill } from "react-bootstrap-icons"

export default function BookingMessageEntry(props: {
    bookingMessage: PropertyBookingMessageType,
    propertyInfo?: PropertyType,
    full?: boolean,
}) {
    const {
        bookingMessage,
        propertyInfo,
        full,
    } = props

    let replyLink = ''
    if(bookingMessage.replyLink) {
        replyLink = bookingMessage.replyLink
    }

    return (
        <Card className="mb-3">
            <Card.Body>
                <Card.Title>
                    <b>{bookingMessage.name}:</b> {timeService.getFormattedTime(bookingMessage.date)} 
                    {replyLink && (
                        <a className="ms-2" href={replyLink} target="_blank" rel="noreferrer">
                            <ReplyFill />
                        </a>
                    )}
                </Card.Title>
                <Card.Text>
                    {bookingMessage.message} <br />
                    {full && (
                        <>
                            <b>Stay:</b> {timeService.getFormattedTime(bookingMessage.checkIn, 'MM/dd')} - {timeService.getFormattedTime(bookingMessage.checkOut, 'MM/dd')} <br />
                            <b>Property:</b> {propertyInfo && propertyInfo.title ? propertyInfo.title : bookingMessage.propertyId} <br />
                            <b>Booking:</b> {bookingMessage.bookingId} <br />
                        </>
                    )}
                </Card.Text>
            </Card.Body>
            {full && (
                <Card.Footer>
                    <Link className="btn btn-primary btn-sm me-1 mb-1" to={`/view-booking-messages/${bookingMessage.bookingId}`}>
                        View Convo
                    </Link>
                </Card.Footer>
            )}
        </Card>
    )

}