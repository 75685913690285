import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { SelectPropertyModal } from "../../../Components/Modals/SelectPropertyModal/selectPropertyModal";
import { blankProperty } from "../../../Components/Forms/PropertyForm/PropertyFormHelpers";
import { useSelector } from "react-redux";
import { accountSelector } from "../../../redux/account/accountSlice";
import QuickForm from "../../../Components/Forms/QuickForm/QuickForm";
import { useAddNewPropertyMutation, useLazyGetPropertyByIdQuery } from "../../../services/bClientApi";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { formatAddress } from "../../../Helpers/Property/propertyAddressFormatters";
import { FieldGeneratorFieldType } from "../../../Components/FormFields/FieldGenerator/FieldGenerator";

const quickPropertySteps = [
    'selectPropertyBase',
    'commonPropertyDetails',
    'saveProperty',
]

export default function QuickAddPropertyPage() {
    const { email, role } = useSelector(accountSelector)
    const [selectPropertyModal, setSelectPropertyModal] = useState(false)
    const [quickPropertyStep, setQuickPropertyStep] = useState(0)

    const [newPropertyData, setNewPropertyData] = useState<any>({})

    const [
        addNewProperty,
        {
            isLoading,
            // error,
        }
    ] = useAddNewPropertyMutation()

    const [
        getPropertyData,
        // {
        //     isLoading: isGettingProperty,
        //     error,
        // }
    ] = useLazyGetPropertyByIdQuery()

    const dispatch = useAppDispatch()

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Quick Property</h1>
                    <hr />
                </Col>
            </Row>
            {quickPropertySteps[quickPropertyStep] === 'selectPropertyBase' && (
                <Row>
                    <Col>
                        <h2>Select Property Base</h2>
                        <hr />
                        <div className="flex">
                            <Button
                                className="me-2 mb-2"
                                onClick={() => {
                                    console.log('use blank property')
                                    const newPropertyDataCopy: any = blankProperty()

                                    // add self to authorizedUser
                                    newPropertyDataCopy.authorizedUsers = [{
                                        email: email,
                                        role: role
                                    }]

                                    setNewPropertyData(newPropertyDataCopy)
                                    setQuickPropertyStep(1)
                                }}
                            >
                                Use Blank Property
                            </Button>
                            <Button
                                className="me-2 mb-2"
                                onClick={() => setSelectPropertyModal(true)}
                            >
                                Use Existing Property
                            </Button>
                        </div>

                        <SelectPropertyModal
                            active={selectPropertyModal}
                            onClose={() => setSelectPropertyModal(false)}
                            onSelect={async (selectedProperty: any) => {
                                let newPropertyDataCopy = JSON.parse(JSON.stringify(selectedProperty))

                                const propertyData = await getPropertyData(selectedProperty._id).unwrap()
                                if(propertyData) {
                                    newPropertyDataCopy = {...propertyData}
                                }

                                delete newPropertyDataCopy['_id']

                                // clear aliases
                                newPropertyDataCopy['aliases'] = []
                                // clear calendars
                                newPropertyDataCopy['calendars'] = []
                                // clear listed
                                newPropertyDataCopy['listed'] = []

                                // filter out owner from authorized users
                                newPropertyDataCopy['authorizedUsers'] = selectedProperty.authorizedUsers.filter((u: { role: string }) => {
                                    // remove owner from authorized users
                                    return u.role !== 'owner'
                                })

                                // filter out remoteLock from linkedServices
                                newPropertyDataCopy['linkedServices'] = selectedProperty.linkedServices.filter((ls: { linkedService: string }) => {
                                    return ls.linkedService !== 'remoteLock'
                                })

                                // clear relationships
                                newPropertyDataCopy['multiUnit'] = {
                                    isMultiUnit: false,
                                    linkedUnits: [],
                                }

                                setNewPropertyData(newPropertyDataCopy)
                                setSelectPropertyModal(false)
                                setQuickPropertyStep(1)
                            }}
                        />

                    </Col>
                </Row>
            )}

            {quickPropertySteps[quickPropertyStep] === 'commonPropertyDetails' && (
                <Row>
                    <Col>
                        <h2>Common Property Details</h2>
                        {/* {JSON.stringify(newPropertyData)} */}

                        <QuickForm
                            initialData={{
                                internalTitle: newPropertyData.title.split(' - ')[0],
                                publicTitle: newPropertyData.title.split(' - ')[1] ? newPropertyData.title.split(' - ')[1] : newPropertyData.title.split(' - ')[0],

                                ownerFirstName: newPropertyData.owner.firstName,
                                ownerLastName: newPropertyData.owner.lastName,
                                ownerPhone: newPropertyData.owner.phone,
                                ownerEmail: newPropertyData.owner.email,

                                address1: newPropertyData.address.address1,
                                address2: newPropertyData.address.address2,
                                city: newPropertyData.address.city,
                                state: newPropertyData.address.state,
                                zip: newPropertyData.address.zip,
                                country: newPropertyData.address.country,

                                airbnbTitle: '',
                                airbnbUrl: '',
                                airbnbCalendar: '',

                                vrboTitle: '',
                                vrboUrl: '',
                                vrboCalendar: '',
                            }}
                            fields={QUICK_ADD_PROPERTY_FIELDS}
                            onSubmit={(values: any) => {
                                const currentPropertyValues = JSON.parse(JSON.stringify(newPropertyData))

                                const {
                                    internalTitle,
                                    publicTitle,

                                    ownerFirstName,
                                    ownerLastName,
                                    ownerPhone,
                                    ownerEmail,

                                    address1,
                                    address2,
                                    city,
                                    state,
                                    zip,
                                    country,

                                    airbnbTitle,
                                    airbnbUrl,
                                    airbnbCalendar,

                                    vrboTitle,
                                    vrboUrl,
                                    vrboCalendar,
                                } = values

                                const propertyTitle = `${internalTitle} - ${publicTitle}`
                                currentPropertyValues.title = propertyTitle

                                currentPropertyValues.aliases.push({
                                    alias: internalTitle,
                                    aliasSource: 'default'
                                })

                                currentPropertyValues.owner.firstName = ownerFirstName
                                currentPropertyValues.owner.lastName = ownerLastName
                                currentPropertyValues.owner.phone = ownerPhone
                                currentPropertyValues.owner.email = ownerEmail

                                currentPropertyValues.address.address1 = address1
                                currentPropertyValues.address.address2 = address2
                                currentPropertyValues.address.city = city
                                currentPropertyValues.address.state = state
                                currentPropertyValues.address.zip = zip
                                currentPropertyValues.address.country = country

                                if (airbnbTitle && airbnbUrl && airbnbCalendar) {
                                    currentPropertyValues.calendars.push({
                                        source: 'airbnb',
                                        url: airbnbCalendar,
                                    })
                                    currentPropertyValues.listed.push({
                                        source: 'airbnb',
                                        url: airbnbUrl,
                                        internalTitle: airbnbTitle,
                                        internalName: airbnbTitle,
                                    })
                                }

                                if (vrboTitle && vrboUrl && vrboCalendar) {
                                    currentPropertyValues.calendars.push({
                                        source: 'vrbo',
                                        url: vrboCalendar,
                                    })
                                    currentPropertyValues.listed.push({
                                        source: 'homeaway',
                                        url: vrboUrl,
                                        internalTitle: vrboTitle,
                                        internalName: vrboTitle,
                                    })
                                }

                                currentPropertyValues.created = new Date()
                                // save property
                                setNewPropertyData(currentPropertyValues)
                                setQuickPropertyStep(2)
                            }}
                        />
                    </Col>
                </Row>
            )}

            {quickPropertySteps[quickPropertyStep] === 'saveProperty' && (
                <Row>
                    <Col>
                        <h2>Save Property</h2>
                        <hr />
                        <b>Title: </b> {newPropertyData.title}
                        <br />
                        <b>Owner: </b> {newPropertyData.owner.firstName} {newPropertyData.owner.lastName}
                        <br />
                        <b>Address: </b> {formatAddress(newPropertyData.address)}
                        <br />
                        <Button
                            onClick={async () => {
                                const newProperty = await addNewProperty(newPropertyData).unwrap()
                                if (newProperty._id) {
                                    dispatch(navigateTo(`/property/${newProperty._id}`))
                                }
                                return newProperty
                            }}
                            disabled={isLoading}
                        >
                            Save Property
                        </Button>
                    </Col>
                </Row>
            )}



        </Container>
    )
}

const QUICK_ADD_PROPERTY_FIELDS: FieldGeneratorFieldType[] = [
    {
        fieldName: 'internalTitle',
        fieldLabel: 'Internal Title',
        fieldType: 'text',
        required: true,
        subText: 'ex: 12345 Codename',
    },
    {
        fieldName: 'publicTitle',
        fieldLabel: 'Public Title',
        fieldType: 'text',
        required: true,
        subText: 'ex: Cool Suite ocean view |2BDR + 1BA| neato',
    },
    {
        fieldName: 'ownerInfo__title',
        fieldType: 'custom',
        fieldLabel: 'Owner Information',
        extra: {
            title: 'Owner Information',
        }
    },
    {
        fieldName: 'ownerFirstName',
        fieldLabel: 'Owner First Name',
        fieldType: 'text',
        required: true,
        layout: {
            xs: 12,
            sm: 6,
        },
    },
    {
        fieldName: 'ownerLastName',
        fieldLabel: 'Owner Last Name',
        fieldType: 'text',
        required: true,
        layout: {
            xs: 12,
            sm: 6,
        }
    },
    {
        fieldName: 'ownerPhone',
        fieldLabel: 'Owner Phone',
        fieldType: 'phone',
        required: true,
        layout: {
            xs: 12,
            sm: 6,
        }
    },
    {
        fieldName: 'ownerEmail',
        fieldLabel: 'Owner Email',
        fieldType: 'text',
        required: true,
        layout: {
            xs: 12,
            sm: 6,
        }
    },
    {
        fieldName: 'propertyAddress__title',
        fieldType: 'custom',
        fieldLabel: 'Property Address',
        extra: {
            title: 'Property Address',
        }
    },
    {
        fieldName: 'address1',
        fieldLabel: 'Address 1',
        fieldType: 'text',
        required: true,
        layout: {
            xs: 12,
            sm: 8,
        }
    },
    {
        fieldName: 'address2',
        fieldLabel: 'Address 2',
        fieldType: 'text',
        layout: {
            xs: 12,
            sm: 4,
        }
    },
    {
        fieldName: 'city',
        fieldLabel: 'City',
        fieldType: 'text',
        required: true,
        layout: {
            xs: 12,
            sm: 3,
        },
    },
    {
        fieldName: 'state',
        fieldLabel: 'State',
        fieldType: 'text',
        required: true,
        layout: {
            xs: 12,
            sm: 3,
        },
    },
    {
        fieldName: 'zip',
        fieldLabel: 'Zip',
        fieldType: 'text',
        required: true,
        layout: {
            xs: 12,
            sm: 3,
        },
    },
    {
        fieldName: 'country',
        fieldLabel: 'Country',
        fieldType: 'text',
        required: true,
        layout: {
            xs: 12,
            sm: 3,
        },
    },
    {
        fieldName: 'airbnbInfo__title',
        fieldType: 'custom',
        fieldLabel: 'Airbnb Information',
        extra: {
            title: 'Airbnb Information',
        }
    },

    {
        fieldName: 'airbnbTitle',
        fieldLabel: 'Airbnb Title',
        fieldType: 'text',
    },
    {
        fieldName: 'airbnbUrl',
        fieldLabel: 'Airbnb Url',
        fieldType: 'text',
        subText: 'ex: https://www.airbnb.com/rooms/XXX',
        layout: {
            xs: 12,
            sm: 6,
        },
    },
    {
        fieldName: 'airbnbCalendar',
        fieldLabel: 'Airbnb Calendar',
        fieldType: 'text',
        subText: 'ex: https://www.airbnb.com/calendar/ical/XXX.ics?s=YYY',
        layout: {
            xs: 12,
            sm: 6,
        },
    },

    {
        fieldName: 'vrboInfo__title',
        fieldType: 'custom',
        fieldLabel: 'VRBO Information',
        extra: {
            title: 'VRBO Information',
        }
    },

    {
        fieldName: 'vrboTitle',
        fieldLabel: 'VRBO Title',
        fieldType: 'text',
    },
    {
        fieldName: 'vrboUrl',
        fieldLabel: 'VRBO Url',
        fieldType: 'text',
        subText: 'ex: https://www.vrbo.com/XXX',
        layout: {
            xs: 12,
            sm: 6,
        },
    },
    {
        fieldName: 'vrboCalendar',
        fieldLabel: 'VRBO Calendar',
        fieldType: 'text',
        subText: 'ex: http://www.vrbo.com/icalendar/YYY.ics?nonTentative',
        layout: {
            xs: 12,
            sm: 6,
        },
    },
]

