import { useState } from "react";
import { Button, Col, Collapse, Row } from "react-bootstrap";
import './collapsible.scss';

export default function Collapsible(
    props: {
        sectionName?: string,
        children: JSX.Element,
        openText?: string,
        closeText?: string,
        forceOpen?: boolean
        initialValue?: boolean
        onToggle?: (sectionName: string, isOpen: boolean) => void,
        title?: string,
        TitleTag?: any,
        titleClass?: string,
    }
) {
    const {
        sectionName,
        initialValue,
        forceOpen,
        onToggle,
        children,
        openText,
        closeText,
        title,
        TitleTag = 'h4',
        titleClass = '',
    } = props;

    const [isOpen, setIsOpen] = useState(initialValue ? initialValue : false);

    const toggle = () => {
        setIsOpen(!isOpen);
        if (onToggle && sectionName) {
            onToggle(sectionName, isOpen);
        }
    }

    return (
        <div className="collapsible-container mb-3">
            {title && (
                <Row className={'collapsible-title-trigger'}>
                    <Col
                        role="button"
                        onClick={() => {
                            if (!forceOpen) {
                                toggle();
                            }
                        }}
                        xs={9}
                    >
                        <TitleTag
                            className={titleClass}
                            // style={{ cursor: forceOpen ? 'default' : 'pointer' }}
                        >{title}</TitleTag>
                    </Col>
                    {!forceOpen && (
                        <Col className="text-end" xs={3} style={{ display: 'flex', alignItems: 'center' }}>
                            <Button
                                size={'sm'}
                                style={{ marginLeft: 'auto' }}
                                variant={'secondary'}
                                onClick={toggle}>
                                {isOpen ? (closeText ? closeText : 'Close') : (openText ? openText : 'Open')}
                            </Button>
                        </Col>
                    )}
                </Row>
            )}
            {!title && !forceOpen && (
                <Button
                    className="mb-3"
                    size={'sm'}
                    variant={'secondary'}
                    onClick={toggle}>
                    {isOpen ? (closeText ? closeText : 'Close') : (openText ? openText : 'Open')}
                </Button>
            )}
            <Collapse in={forceOpen || isOpen}>
                <div>
                    {forceOpen || isOpen ? (
                        children
                    ) : (<div />)}

                </div>
            </Collapse>
        </div>
    )
}