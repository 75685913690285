import { Form, Formik } from "formik";
import { useState } from "react";
import { ArrayFieldInput } from "../../FormFields/ArrayInput/ArrayFieldInput";
import { CheckBoxInputField } from "../../FormFields/CheckBoxInput/CheckBoxInputField";
import ImageUploadField from "../../FormFields/ImageUpload/ImageUpload";
import { TextInputField } from "../../FormFields/TextInput/TextInputField";
import { PropertyType } from "../../../Types/propertyTypes";
import './propertyForm.scss'
import { blankProperty, propertyEligibility, propertySchema } from "./PropertyFormHelpers";
import {
  aboutSectionsFieldValues,
  aliasFieldValues,
  authorizedUsersFieldValues,
  calendarFieldValues,
  earlyCheckInLinkedService,
  extraFeesFieldValues,
  fontainePortalLinkedService,
  hotelCleaningFee,
  hotelDepositFee,
  hotelResortFee,
  hourSelection,
  lateCheckOutLinkedService,
  listedFieldValues,
  minuteSelection,
  parkingLinkedService,
  remoteLockLinkedService
} from "./PropertyFormConstants";
import { Button, Col, Row } from "react-bootstrap";
import Collapsible from "../../Collapsible/Collapsible";
import { modImageName } from "../../FormFields/ImageUpload/imageUploadHelpers";
import { imageGalleryFieldValues, linkedServicesFieldValues } from "../commonFieldValues";
import AddressInputField from "../../FormFields/AddressInput/AddressInputField";
import { DateInputField } from "../../FormFields/DateInput/DateInputField";
import { PhoneNumberInputField } from "../../FormFields/PhoneNumberInput/PhoneNumberInputField";
import { SelectInputField } from "../../FormFields/SelectInput/SelectInputField";
import { SelectPropertyModal } from "../../Modals/SelectPropertyModal/selectPropertyModal";
import SelectUserModal from "../../Modals/SelectUserModal/selectUserModal";
import { checkVal } from "../../../Helpers/Parsers/checkVal";

export const PropertyForm = (props: {
  disabled: boolean,
  onSubmit: (v: any) => Promise<any>,
  uploadImageFn: (v: any) => Promise<string>,
  uploadImageMultiFn: (v: { imageToUpload: any, filename: string }[]) => Promise<string[]>,
  imageUploadProgress: string,
  initialValues?: Partial<PropertyType>,
  propertyOptionList: { value: string, text: string }[]
}) => {

  const {
    disabled,
    onSubmit,
    uploadImageFn,
    uploadImageMultiFn,
    imageUploadProgress,
    initialValues,
    propertyOptionList
  } = props

  const [uploadingImage, setUploadingImage] = useState(false)

  const [copyPropertySettingsModal, setCopyPropertySettingsModal] = useState('')

  const [selectUserModal, setSelectUserModal] = useState(false)

  return (
    <div className="property-form">
      <Formik
        initialValues={{
          ...blankProperty(),
          ...(initialValues ? initialValues : {})
        }}
        validationSchema={propertySchema}
        // validate={values => { const errors: any = {}; return errors; }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const submissionResponse = await onSubmit(values)
            if (!submissionResponse.error) {
              return
            }
          } catch (err) {
            // custom error handler
          }
          setSubmitting(false)
        }}
      >
        {({ values, errors, touched, setFieldValue, isSubmitting, validateForm }) => {

          const eligible = propertyEligibility(values)

          return (
            <>
              {/* Scroll to guestInstructionSection */}
              <Button
                onClick={() => {
                  const guestInstructionSection = document.getElementById('guestInstructionSection')
                  if (guestInstructionSection) {
                    guestInstructionSection.scrollIntoView({ behavior: "smooth" })
                  }
                }}
                size={'sm'}
                variant={'secondary'}
              >
                Scroll to Guest Instructions
              </Button>
              <br />

              <Form>

                <h3>General Info</h3>
                <TextInputField
                  fieldLabel="Title"
                  placeholder="title"
                  required={true}
                  fieldName="title" />

                <TextInputField
                  fieldLabel="Description"
                  placeholder="Description"
                  required={true}
                  fieldType={'textarea'}
                  fieldName="description" />


                {/* <h3>Property Aliases</h3> */}
                <Collapsible
                  title="Property Aliases"
                  TitleTag="h3"
                >
                  <div>
                    <label htmlFor="Aliases">Aliases</label><br />
                    <ArrayFieldInput
                      fieldName={'aliases'}
                      values={values.aliases}
                      setFieldValue={setFieldValue}
                      blankEntryFn={() => ({ alias: '', aliasSource: '' })}
                      fields={aliasFieldValues} />
                  </div>
                </Collapsible>

                {/* <h3>Owner Information</h3> */}
                <Collapsible
                  title="Owner Information"
                  TitleTag="h3"
                >
                  <div>
                    <Row>
                      <Col xs={12} md={6}>
                        <TextInputField
                          fieldLabel="Owner First Name"
                          placeholder="Owner First Name"
                          fieldName={`owner.firstName`} />
                      </Col>
                      <Col xs={12} md={6}>
                        <TextInputField
                          fieldLabel="Owner Last Name"
                          placeholder="Owner Last Name"
                          fieldName={`owner.lastName`} />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} md={6}>
                        <PhoneNumberInputField
                          fieldLabel="Owner Phone"
                          placeholder="Owner Phone"
                          fieldName={`owner.phone`} />
                      </Col>
                      <Col xs={12} md={6}>
                        <TextInputField
                          fieldLabel="Owner Email"
                          placeholder="Owner Email"
                          fieldName={`owner.email`} />
                      </Col>
                    </Row>
                  </div>
                </Collapsible>


                <h3>Property Address</h3>
                <AddressInputField
                  fieldName={'address'}
                  required={true}
                />


                <h3>Featured Image</h3>
                {values.featuredImage && (
                  <>
                    <img alt={'featured preview'} src={values.featuredImage} className="image-preview" />
                    <br />
                  </>
                )}
                <TextInputField
                  fieldLabel="Featured Image"
                  placeholder="Featured Image"
                  fieldName={`featuredImage`} />

                <TextInputField
                  fieldLabel="Thumbnail Image"
                  placeholder="Thumbnail Image"
                  fieldName={`thumbnailImage`} />

                <ImageUploadField
                  key={'featuredImageUpload'}
                  uploadedImageCallback={async (imageInfo: any, thumbInfo: any) => {
                    setUploadingImage(true)
                    try {
                      const uploadedImageUrl = await uploadImageFn(imageInfo)
                      const uploadedThumbUrl = await uploadImageFn({
                        ...thumbInfo,
                        filename: modImageName(thumbInfo.filename, '_thumb')
                      })
                      if (uploadedImageUrl) {
                        setFieldValue('featuredImage', uploadedImageUrl)
                        setFieldValue('thumbnailImage', uploadedThumbUrl)
                      }
                    } catch (err) {
                      // something went wrong
                    }
                    setUploadingImage(false)
                  }}
                />
                <br />
                {/*<ErrorMessage name="featuredImage" component="div" />*/}

                {/* <h3>PropertyImages</h3> */}
                <Collapsible
                  title="Property Images"
                  TitleTag="h3"
                >
                  <div>
                    <label htmlFor="Image Gallery">Image Gallery</label><br />
                    <ArrayFieldInput
                      fieldName={'imageGallery'}
                      customClass={'image-gallery-drag-container'}
                      values={values.imageGallery}
                      setFieldValue={setFieldValue}
                      blankEntryFn={() => ({ url: '', title: '', description: '', thumbUrl: '' })}
                      imgPreview={{
                        srcVal: 'url'
                      }}
                      fields={imageGalleryFieldValues} />
                    {imageUploadProgress && (<>{imageUploadProgress} <br /></>)}
                    <ImageUploadField
                      key={'imageGalleryUpload'}
                      multipleImages={true}
                      uploadedImageCallback={async (imagesInfo: any[], thumbsInfo: any[]) => {
                        setUploadingImage(true)
                        try {
                          const uploadedImageList = await uploadImageMultiFn(imagesInfo)
                          const uploadedThumbs = await uploadImageMultiFn(thumbsInfo.map(x => {
                            return {
                              ...x,
                              filename: modImageName(x.filename, '_thumb')
                            }
                          }))
                          if (uploadedImageList) {
                            const mappedUploadedImages = uploadedImageList.map((imgUrl: string, idx: number) => {
                              const thumbUrl = uploadedThumbs && uploadedThumbs[idx] ? uploadedThumbs[idx] : ''
                              return {
                                url: imgUrl,
                                title: '',
                                description: '',
                                thumbUrl
                              }
                            })
                            setFieldValue('imageGallery', [...values.imageGallery, ...mappedUploadedImages])
                          }
                        } catch (err) {
                          // something went wrong
                        }
                        setUploadingImage(false)
                      }}
                    />
                  </div>
                </Collapsible>

                {/* <h3>Calendars</h3> */}
                <Collapsible
                  title="Calendars"
                  TitleTag="h3"
                >
                  <div>
                    <label htmlFor="Calendars">Calendars</label><br />
                    <ArrayFieldInput
                      fieldName={'calendars'}
                      values={values.calendars}
                      setFieldValue={setFieldValue}
                      blankEntryFn={() => ({ url: '', source: '' })}
                      fields={calendarFieldValues} />
                  </div>
                </Collapsible>


                {/* <h3>Listed</h3> */}
                <Collapsible
                  title="Listed"
                  TitleTag="h3"
                >
                  <div>
                    <label htmlFor="Listed">Listed</label><br />
                    <ArrayFieldInput
                      fieldName={'listed'}
                      values={values.listed}
                      setFieldValue={setFieldValue}
                      blankEntryFn={() => ({ url: '', source: '', internalName: '', internalTitle: '' })}
                      fields={listedFieldValues} />
                  </div>
                </Collapsible>

                <h3>Property Details</h3>
                <TextInputField
                  fieldLabel="Summary"
                  placeholder="Summary"
                  fieldType={'textarea'}
                  fieldName={`about.summary`} />

                <TextInputField
                  fieldType="number"
                  fieldLabel="Guests"
                  required={true}
                  placeholder="Guests"
                  fieldName={`about.guests`} />

                <h4>Guest Breakdown</h4>
                <Row>
                  <Col xs={12} md={4}>
                    <TextInputField
                      fieldType="number"
                      fieldLabel="Adults"
                      placeholder="Adults"
                      fieldName={`about.guestInfo.adults`} />
                  </Col>
                  <Col xs={12} md={4}>
                    <TextInputField
                      fieldType="number"
                      fieldLabel="Children"
                      placeholder="Children"
                      fieldName={`about.guestInfo.children`} />
                  </Col>
                  <Col xs={12} md={4}>
                    <TextInputField
                      fieldType="number"
                      fieldLabel="Infants"
                      placeholder="Infants"
                      fieldName={`about.guestInfo.infants`} />
                  </Col>
                </Row>



                <h4>Rooms and Baths</h4>

                <Row>
                  <Col xs={12} md={4}>
                    <TextInputField
                      fieldType="number"
                      fieldLabel="Bedrooms"
                      required={true}
                      placeholder="Bedrooms"
                      fieldName={`about.bedrooms`} />
                  </Col>
                  <Col xs={12} md={4}>
                    <TextInputField
                      fieldType="number"
                      fieldLabel="Beds"
                      required={true}
                      placeholder="Beds"
                      fieldName={`about.beds`} />
                  </Col>
                  <Col xs={12} md={4}>
                    <TextInputField
                      fieldType="number"
                      fieldLabel="Other Beds"
                      required={true}
                      placeholder="Other Beds (sofabeds, hammocks, etc...)"
                      fieldName={`about.otherBeds`} />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={4}>
                    <TextInputField
                      fieldType="number"
                      fieldLabel="Bathrooms"
                      required={true}
                      placeholder="Bathrooms"
                      fieldName={`about.bathrooms`} />

                  </Col>
                  <Col xs={12} md={4}>
                    <TextInputField
                      fieldType="number"
                      fieldLabel="Baths"
                      required={true}
                      placeholder="Baths"
                      fieldName={`about.baths`} />
                  </Col>
                  <Col xs={12} md={4}>

                    <TextInputField
                      fieldType="number"
                      fieldLabel="Half Bathrooms"
                      required={true}
                      placeholder="Half Bathrooms"
                      fieldName={`about.halfBathrooms`} />
                  </Col>
                </Row>




                <label htmlFor="Amenities">Amenities</label><br />
                UPDATE AMENITIES COMPONENT<br /><br />
                {/*<SimpleArrayFieldInput
              fieldName={'about.amenities'}
              values={values.about.amenities}
              possibleValues={[
                { value: 'bbq', text: 'BBQ' },
                { value: 'microwave', text: 'Microwave' },
                { value: 'heatedTub', text: 'Heated Tub' },
                { value: 'poolTable', text: 'Pool Table' },
              ]}
            /><br />*/}
                {/* <h4>Description Sections</h4> */}
                <Collapsible
                  title="Description Sections"
                  TitleTag="h4"
                >
                  <div>
                    <label htmlFor="Description Sections">Description Sections</label><br />
                    <ArrayFieldInput
                      fieldName={'about.sections'}
                      values={values.about.sections}
                      setFieldValue={setFieldValue}
                      blankEntryFn={() => ({ sectionTitle: '', content: '' })}
                      fields={aboutSectionsFieldValues} />
                  </div>
                </Collapsible>

                <div
                  id="guestInstructionSection"
                  style={{
                    scrollMarginTop: '100px'
                  }}
                />


                <Collapsible
                  title="Guest Instructions"
                  TitleTag="h4"
                >
                  <div>
                    <label htmlFor="Guest Instructions">Guest Instructions</label><br />

                    {/* begin guest instruction buttons */}

                    {eligible?.guestInstructions?.general && (
                      <Button
                        className={"me-2 mb-2"}
                        variant={"secondary"}
                        size={"sm"}
                        onClick={() => {
                          setFieldValue(
                            'about.guestInstructions',
                            [
                              ...values.about.guestInstructions,
                              { sectionId: 'general', sectionTitle: 'General', content: '' }
                            ]
                          )
                        }}>
                        + General
                      </Button>
                    )}

                    {eligible?.guestInstructions?.amenities && (
                      <Button
                        className={"me-2 mb-2"}
                        variant={"secondary"}
                        size={"sm"}
                        onClick={() => {
                          setFieldValue(
                            'about.guestInstructions',
                            [
                              ...values.about.guestInstructions,
                              { sectionId: 'amenities', sectionTitle: 'Amenities', content: '' }
                            ]
                          )
                        }}>
                        + Amenities
                      </Button>
                    )}

                    {eligible?.guestInstructions?.additionalFees && (
                      <Button
                        className={"me-2 mb-2"}
                        variant={"secondary"}
                        size={"sm"}
                        onClick={() => {
                          setFieldValue(
                            'about.guestInstructions',
                            [
                              ...values.about.guestInstructions,
                              { sectionId: 'additionalFees', sectionTitle: 'Additional Fees', content: '' }
                            ]
                          )
                        }}>
                        + Additional Fees
                      </Button>
                    )}

                    {eligible?.guestInstructions?.lateCheckInFee && (
                      <Button
                        className={"me-2 mb-2"}
                        variant={"secondary"}
                        size={"sm"}
                        onClick={() => {
                          setFieldValue(
                            'about.guestInstructions',
                            [
                              ...values.about.guestInstructions,
                              { sectionId: 'lateCheckInFee', sectionTitle: 'Late Check In Fee', content: 'After 10PM an extra $50 fee\nAfter 12AM an extra  $100 fee' }
                            ]
                          )
                        }}>
                        + Late Check In Fee
                      </Button>
                    )}

                    {eligible?.guestInstructions?.lock && (
                      <Button
                        className={"me-2 mb-2"}
                        variant={"secondary"}
                        size={"sm"}
                        onClick={() => {
                          setFieldValue(
                            'about.guestInstructions',
                            [
                              ...values.about.guestInstructions,
                              { sectionId: 'lock', sectionTitle: 'Lock', content: '' }
                            ]
                          )
                        }}>
                        + Lock
                      </Button>
                    )}

                    {eligible?.guestInstructions?.internet && (
                      <Button
                        className={"me-2 mb-2"}
                        variant={"secondary"}
                        size={"sm"}
                        onClick={() => {
                          setFieldValue(
                            'about.guestInstructions',
                            [
                              ...values.about.guestInstructions,
                              {
                                sectionId: 'internet',
                                sectionTitle: 'Internet',
                                content: `WIFI NAME: XXXXXXXX\nWIFI PASSWORD: ZZZZZZZZ`
                              }
                            ]
                          )
                        }}>
                        + Internet
                      </Button>
                    )}

                    {eligible?.guestInstructions?.parking && (
                      <Button
                        className={"me-2 mb-2"}
                        variant={"secondary"}
                        size={"sm"}
                        onClick={() => {
                          setFieldValue(
                            'about.guestInstructions',
                            [
                              ...values.about.guestInstructions,
                              { sectionId: 'parking', sectionTitle: 'Parking', content: '' }
                            ]
                          )
                        }}>
                        + Parking
                      </Button>
                    )}

                    {eligible?.guestInstructions?.luggageStorage && (
                      <Button
                        className={"me-2 mb-2"}
                        variant={"secondary"}
                        size={"sm"}
                        onClick={() => {
                          setFieldValue(
                            'about.guestInstructions',
                            [
                              ...values.about.guestInstructions,
                              { sectionId: 'luggageStorage', sectionTitle: 'Luggage Storage', content: 'We have partnered with Bounce. A luggage storage facility in the area that can hold your luggage for you.\nYou may use our link below to gain a 5% discount off your luggage storage.\nhttps://bounce.com/s/location/08712ce3-eda4-43ba-83cc-7d76e3d00081?ref=BENICHAYBROTHERS&coupon=BENICHAYBROTHERS' }
                            ]
                          )
                        }}>
                        + Luggage Storage
                      </Button>
                    )}

                    {eligible?.guestInstructions?.packageDelivery && (
                      <Button
                        className={"me-2 mb-2"}
                        variant={"secondary"}
                        size={"sm"}
                        onClick={() => {
                          setFieldValue(
                            'about.guestInstructions',
                            [
                              ...values.about.guestInstructions,
                              { sectionId: 'packageDelivery', sectionTitle: 'Package Delivery', content: 'We do not hold packages for liability reasons. We recommend using a package receiving company in the area that will hold your packages until you arrive. Here is a link to use this service through Bounce.\nhttps://bounce.com/packages/location?query=Miami%20Beach%2C%20FL%2C%20USA' }
                            ]
                          )
                        }}>
                        + Package Delivery
                      </Button>
                    )}

                    {values?.about?.guestInstructions?.length === 0 && (
                      <Button
                        className={"me-2 mb-2"}
                        variant={"dark"}
                        size={"sm"}
                        onClick={() => {
                          setCopyPropertySettingsModal('about.guestInstructions')
                        }}>
                        Copy Other Property
                      </Button>
                    )}

                    {/* end guest instruction buttons */}

                    <ArrayFieldInput
                      fieldName={'about.guestInstructions'}
                      values={values.about.guestInstructions}
                      setFieldValue={setFieldValue}
                      blankEntryFn={() => ({ sectionId: '', sectionTitle: '', content: '' })}
                      fields={aboutSectionsFieldValues} />
                  </div>
                </Collapsible>


                <Collapsible
                  title="Check Out Instructions"
                  TitleTag="h4"
                >
                  <div>
                    <label htmlFor="Guest Instructions">Guest Instructions</label><br />

                    {/* begin guest instruction buttons */}

                    {eligible?.guestCheckOutInstructions?.trash && (
                      <Button
                        className={"me-2 mb-2"}
                        variant={"secondary"}
                        size={"sm"}
                        onClick={() => {
                          setFieldValue(
                            'about.guestCheckOutInstructions',
                            [
                              ...values.about.guestCheckOutInstructions,
                              { sectionId: 'trash', sectionTitle: 'Throw trash away', content: 'Please place trash in outside bins.' }
                            ]
                          )
                        }}>
                        + Trash
                      </Button>
                    )}

                    {eligible?.guestCheckOutInstructions?.turnThingsOff && (
                      <Button
                        className={"me-2 mb-2"}
                        variant={"secondary"}
                        size={"sm"}
                        onClick={() => {
                          setFieldValue(
                            'about.guestCheckOutInstructions',
                            [
                              ...values.about.guestCheckOutInstructions,
                              { sectionId: 'turnThingsOff', sectionTitle: 'Turn Things Off', content: 'Please turn off all lights and set thermostat to 74 degrees.' }
                            ]
                          )
                        }}>
                        + Turn Things Off
                      </Button>
                    )}

                    {eligible?.guestCheckOutInstructions?.lockUp && (
                      <Button
                        className={"me-2 mb-2"}
                        variant={"secondary"}
                        size={"sm"}
                        onClick={() => {
                          setFieldValue(
                            'about.guestCheckOutInstructions',
                            [
                              ...values.about.guestCheckOutInstructions,
                              { sectionId: 'lockUp', sectionTitle: 'Lock Up', content: 'Close the door behind you.' }
                            ]
                          )
                        }}>
                        + Lock Up
                      </Button>
                    )}

                    {eligible?.guestCheckOutInstructions?.returnKeys && (
                      <Button
                        className={"me-2 mb-2"}
                        variant={"secondary"}
                        size={"sm"}
                        onClick={() => {
                          setFieldValue(
                            'about.guestCheckOutInstructions',
                            [
                              ...values.about.guestCheckOutInstructions,
                              { sectionId: 'returnKeys', sectionTitle: 'Return Keys', content: 'Return key cards to front desk' }
                            ]
                          )
                        }}>
                        + Return Keys
                      </Button>
                    )}

                    {eligible?.guestCheckOutInstructions?.thermoStat && (
                      <Button
                        className={"me-2 mb-2"}
                        variant={"secondary"}
                        size={"sm"}
                        onClick={() => {
                          setFieldValue(
                            'about.guestCheckOutInstructions',
                            [
                              ...values.about.guestCheckOutInstructions,
                              { sectionId: 'thermoStat', sectionTitle: 'Thermo Stat', content: 'Set thermostat to 74 degrees.' }
                            ]
                          )
                        }}>
                        + Thermo Stat
                      </Button>
                    )}

                    {eligible?.guestCheckOutInstructions?.additionalRequests && (
                      <Button
                        className={"me-2 mb-2"}
                        variant={"secondary"}
                        size={"sm"}
                        onClick={() => {
                          setFieldValue(
                            'about.guestCheckOutInstructions',
                            [
                              ...values.about.guestCheckOutInstructions,
                              { sectionId: 'additionalRequests', sectionTitle: 'Additional Requests', content: '' }
                            ]
                          )
                        }}>
                        + Additional Requests
                      </Button>
                    )}

                    {values?.about?.guestCheckOutInstructions.length === 0 && (
                      <Button
                        className={"me-2 mb-2"}
                        variant={"dark"}
                        size={"sm"}
                        onClick={() => {
                          setCopyPropertySettingsModal('about.guestCheckOutInstructions')
                        }}>
                        Copy Other Property
                      </Button>
                    )}

                    {/* end guest instruction buttons */}

                    <ArrayFieldInput
                      fieldName={'about.guestCheckOutInstructions'}
                      values={values.about.guestCheckOutInstructions}
                      setFieldValue={setFieldValue}
                      blankEntryFn={() => ({ sectionId: '', sectionTitle: '', content: '' })}
                      fields={aboutSectionsFieldValues} />
                  </div>
                </Collapsible>

                {/* registrationMessage field textarea */}
                <TextInputField
                  fieldType="textarea"
                  fieldLabel="Registration Message"
                  placeholder="Registration Message"
                  fieldName={`about.registrationMessage`} />

                <Row className="mb-2">
                  <Col>
                    <h4>Check In / Check Out</h4>
                  </Col>
                  <Col xs="auto">
                    <Button
                      size="sm"
                      onClick={() => {
                        setFieldValue('about.checkInHour', 16)
                        setFieldValue('about.checkInMinute', 0)
                        setFieldValue('about.checkOutHour', 11)
                        setFieldValue('about.checkOutMinute', 0)
                        setTimeout(() => {
                          validateForm()
                        }, 300)
                      }}
                    >
                      Use Standard Times
                    </Button>
                  </Col>

                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <SelectInputField
                      fieldLabel={'Check In Hour'}
                      fieldName={'about.checkInHour'}
                      required={true}
                      placeholder="- Select Hour -"
                      options={hourSelection} />
                  </Col>
                  <Col xs={12} md={6}>
                    <SelectInputField
                      fieldLabel={'Check In Minute'}
                      fieldName={'about.checkInMinute'}
                      required={true}
                      placeholder="- Select Minute -"
                      options={minuteSelection} />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={6}>
                    <SelectInputField
                      fieldLabel={'Check Out Hour'}
                      fieldName={'about.checkOutHour'}
                      required={true}
                      placeholder="- Select Hour -"
                      options={hourSelection} />
                  </Col>
                  <Col xs={12} md={6}>
                    <SelectInputField
                      fieldLabel={'Check Out Minute'}
                      fieldName={'about.checkOutMinute'}
                      required={true}
                      placeholder="- Select Minute -"
                      options={minuteSelection} />
                  </Col>
                </Row>


                <h3>Multi Unit</h3>
                <CheckBoxInputField
                  fieldLabel={'Is this a multi unit?'}
                  fieldName={'multiUnit.isMultiUnit'} />

                {values.multiUnit.isMultiUnit && (
                  <>
                    <label htmlFor="Linked Units">Linked Units</label><br />
                    <ArrayFieldInput
                      fieldName={'multiUnit.linkedUnits'}
                      values={values.multiUnit.linkedUnits}
                      setFieldValue={setFieldValue}
                      blankEntryFn={() => ({ propertyId: '', relationship: '' })}
                      fields={[
                        {
                          fieldType: 'select',
                          fieldLabel: 'Property Id',
                          placeholder: '- Select Property Id -',
                          fieldName: 'propertyId',
                          options: propertyOptionList
                        },
                        {
                          fieldType: 'select',
                          fieldLabel: 'Relationship',
                          placeholder: '- Select Relationship -',
                          fieldName: 'relationship',
                          options: [
                            { value: 'child', text: 'Child' },
                            { value: 'parent', text: 'Parent' },
                          ]
                        },
                      ]} />
                  </>
                )}

                <h3>Pricing</h3>
                <Row>
                  <Col xs={12} md={3}>
                    <TextInputField
                      fieldType="number"
                      fieldLabel="Base Rate"
                      required={true}
                      placeholder="Base Rate"
                      fieldName={`pricing.baseRate`} />
                  </Col>
                  <Col xs={12} md={3}>
                    <TextInputField
                      fieldType="number"
                      fieldLabel="Deposit"
                      required={true}
                      placeholder="Deposit"
                      fieldName={`pricing.deposit`} />
                  </Col>
                  <Col xs={12} md={3}>
                    <TextInputField
                      fieldType="number"
                      fieldLabel="Cleaning"
                      required={true}
                      placeholder="Cleaning"
                      fieldName={`pricing.cleaning`} />
                  </Col>
                  <Col xs={12} md={3}>
                    <TextInputField
                      fieldType="number"
                      fieldLabel="Tax %"
                      required={true}
                      placeholder="Tax %"
                      fieldName={`pricing.tax`} />
                  </Col>
                </Row>

                {/* <h4>Extra Fees</h4> */}
                <Collapsible title="Extra Fees">
                  <div>
                    <label htmlFor="Extra Fees">Extra Fees</label><br />
                    {eligible?.extraFees?.hotelCleaning && (
                      <Button
                        className={"me-2 mb-2"}
                        variant={"secondary"}
                        size={"sm"}
                        onClick={() => {
                          setFieldValue(
                            'pricing.extraFees',
                            [
                              ...values.pricing.extraFees,
                              hotelCleaningFee()
                            ]
                          )
                        }}>
                        + Hotel Cleaning
                      </Button>
                    )}
                    {eligible?.extraFees?.hotelDeposit && (
                      <Button
                        className={"me-2 mb-2"}
                        variant={"secondary"}
                        size={"sm"}
                        onClick={() => {
                          setFieldValue(
                            'pricing.extraFees',
                            [
                              ...values.pricing.extraFees,
                              hotelDepositFee()
                            ]
                          )
                        }}>
                        + Hotel Deposit
                      </Button>
                    )}
                    {eligible?.extraFees?.hotelResort && (
                      <Button
                        className={"me-2 mb-2"}
                        variant={"secondary"}
                        size={"sm"}
                        onClick={() => {
                          setFieldValue(
                            'pricing.extraFees',
                            [
                              ...values.pricing.extraFees,
                              hotelResortFee()
                            ]
                          )
                        }}>
                        + Hotel Resort
                      </Button>
                    )}
                    <ArrayFieldInput
                      fieldName={'pricing.extraFees'}
                      values={values.pricing.extraFees}
                      setFieldValue={setFieldValue}
                      blankEntryFn={() => ({ name: '', amount: 0, frequency: 'once' })}
                      fields={extraFeesFieldValues} />
                  </div>
                </Collapsible>

                <h3>Virtual Tour</h3>
                <TextInputField
                  fieldType="text"
                  fieldLabel="Virtual Tour"
                  placeholder="Virtual Tour"
                  fieldName={`virtualTour`} />

                {/* <h3>Authorized Users</h3> */}
                <Collapsible
                  title="Authorized Users"
                  TitleTag="h3"
                >
                  <div>
                    <label htmlFor="Authorized Users">Authorized Users</label><br />

                    <Button
                      className={"me-2 mb-2"}
                      variant={"secondary"}
                      size={"sm"}
                      onClick={() => {
                        setSelectUserModal(true)
                      }}>
                      + Add User
                    </Button>

                    <ArrayFieldInput
                      fieldName={'authorizedUsers'}
                      values={values.authorizedUsers}
                      setFieldValue={setFieldValue}
                      blankEntryFn={() => ({ email: '', role: '' })}
                      fields={authorizedUsersFieldValues()} />
                  </div>
                </Collapsible>

                <h3>Public Property</h3>
                <CheckBoxInputField
                  fieldLabel={'Display this listing to the public'}
                  fieldName={'public'} />

                <h3>Services</h3>
                <CheckBoxInputField
                  fieldLabel={'This property requires cleaning services'}
                  fieldName={'services.cleaning'} />

                <CheckBoxInputField
                  fieldLabel={'This property requires booking guest info services'}
                  fieldName={'services.bookingGuestInfo'} />

                <CheckBoxInputField
                  fieldLabel={'This property requires identification with the booking guest information'}
                  fieldName={'services.bookingGuestId'} />

                <TextInputField
                  fieldType="text"
                  fieldLabel="Booking Guest Email"
                  placeholder="checkerinner@checkin.com"
                  fieldName={`services.bookingGuestEmail`} />

                <hr />

                {/* <h3>Linked Services</h3> */}
                <Collapsible
                  title="Linked Services"
                  TitleTag="h3"
                >
                  <div>
                    <label htmlFor="Linked Services">Linked Services</label><br />
                    {eligible?.linkedServices?.fontainePortal && (
                      <Button
                        className={"me-2 mb-2"}
                        variant={"secondary"}
                        size={"sm"}
                        onClick={() => {
                          setFieldValue(
                            'linkedServices',
                            [
                              ...values.linkedServices,
                              fontainePortalLinkedService()
                            ]
                          )
                        }}>
                        + Fontaine Portal
                      </Button>
                    )
                    }

                    {/* early check in button and late check out button */}

                    {eligible?.linkedServices?.earlyCheckIn && (
                      <Button
                        className={"me-2 mb-2"}
                        variant={"secondary"}
                        size={"sm"}
                        onClick={() => {
                          setFieldValue(
                            'linkedServices',
                            [
                              ...values.linkedServices,
                              earlyCheckInLinkedService()
                            ]
                          )
                        }}>
                        + Early Check In
                      </Button>
                    )}

                    {eligible?.linkedServices?.lateCheckOut && (
                      <Button
                        className={"me-2 mb-2"}
                        variant={"secondary"}
                        size={"sm"}
                        onClick={() => {
                          setFieldValue(
                            'linkedServices',
                            [
                              ...values.linkedServices,
                              lateCheckOutLinkedService()
                            ]
                          )
                        }}>
                        + Late Check Out
                      </Button>
                    )}

                    {/* Remote lock Service Button */}
                    {eligible?.linkedServices?.remoteLock && (
                      <Button
                        className={"me-2 mb-2"}
                        variant={"secondary"}
                        size={"sm"}
                        onClick={() => {
                          setFieldValue(
                            'linkedServices',
                            [
                              ...values.linkedServices,
                              remoteLockLinkedService()
                            ]
                          )
                        }}>
                        + Remote Lock
                      </Button>
                    )}

                    {/* Parking sevice Button */}
                    {eligible?.linkedServices?.parking && (
                      <Button
                        className={"me-2 mb-2"}
                        variant={"secondary"}
                        size={"sm"}
                        onClick={() => {
                          setFieldValue(
                            'linkedServices',
                            [
                              ...values.linkedServices,
                              parkingLinkedService()
                            ]
                          )
                        }}>
                        + Parking
                      </Button>
                    )}

                    <ArrayFieldInput
                      fieldName={'linkedServices'}
                      values={values.linkedServices}
                      setFieldValue={setFieldValue}
                      blankEntryFn={() => ({ linkedService: '', linkedServiceId: '' })}
                      fields={linkedServicesFieldValues} />
                  </div>
                </Collapsible>


                <DateInputField
                  fieldLabel={'Date property was added'}
                  placeholder={'mm/dd/yyyy'}
                  fieldName={'created'} />

                {/*Props: {JSON.stringify(props)}
            <br />*/}
                Errors: {JSON.stringify(errors)}
                <br />
                <Button className="mb-3" type="submit" disabled={Object.keys(errors).length > 0 || isSubmitting || disabled || uploadingImage}>
                  Submit
                </Button>
              </Form>
              <SelectPropertyModal
                active={copyPropertySettingsModal !== ''}
                onClose={() => setCopyPropertySettingsModal('')}
                onSelect={(selectedProperty: any) => {
                  console.log(selectedProperty)
                  const guestInstruction = checkVal(selectedProperty, copyPropertySettingsModal)
                  // const guestInstruction = selectedProperty.about?.guestInstructions
                  if (guestInstruction) {
                    setFieldValue(copyPropertySettingsModal, guestInstruction)
                  }
                }}
              />

              <SelectUserModal
                active={selectUserModal}
                onClose={() => {
                  setSelectUserModal(false)
                }}
                onSelect={(selectedUser: any) => {
                  console.log(selectedUser)
                  const { email, role } = selectedUser

                  // check if user is already in the list
                  const userExists = values.authorizedUsers.find((user: any) => user.email.toLowerCase() === email.toLowerCase())
                  if (!userExists) {
                    setFieldValue('authorizedUsers', [
                      ...values.authorizedUsers,
                      { email, role }
                    ])
                  }
                  setSelectUserModal(false)
                }}
              />
            </>
          )
        }}
      </Formik>
    </div>
  )
}