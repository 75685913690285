import { useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import QuickForm from "../../Components/Forms/QuickForm/QuickForm";
import { bookingsByDayObject } from "../../Helpers/Bookings/bookingHelpers";
import { currencyFormatter } from "../../Helpers/Parsers/currencyFormatter";
import useMyProperties from "../../Hooks/UseMyProperties/UseMyPropertiesHook";
import { routerSelector } from "../../redux/router/routerSelector";
import { useAppDispatch } from "../../redux/store";
import timeService from "../../services/time/timeService";
import './financialsPage.scss'
import { navigateTo } from "../../redux/router/routerActions";
import BookingPreview from "../Bookings/Booking/BookingPreview";
import { useGetBookingsQuery } from "../../services/bClientApi";
import { bookingSourceIcons } from "../../Components/Bookings/BookingEntry/BookingEntry";

export default function FinancialsPage() {


    const dispatch = useAppDispatch()

    const { myProperties } = useMyProperties()
    const { propertyId = '' } = useParams()

    const { location } = useSelector(routerSelector)

    const urlParams: any = new URLSearchParams(location?.search);

    const [bookingPreview, setBookingPreview] = useState<Partial<any>>({ booking: {} })

    let activeCheckIn = timeService.getCurrentTime().startOf('month').toMillis()
    let activeCheckOut = timeService.getCurrentTime().endOf('month').toMillis()

    if (urlParams.get('checkIn')) {
        activeCheckIn = timeService.createLuxonDate(new Date(+urlParams.get('checkIn'))).toMillis()
        activeCheckOut = timeService.createLuxonDate(new Date(+urlParams.get('checkIn'))).endOf('month').toMillis()
    }
    if (urlParams.get('checkOut')) {
        activeCheckOut = timeService.createLuxonDate(new Date(+urlParams.get('checkOut'))).toMillis()
    }


    const activeQuery = useMemo(() => {
        return {
            query: {
                propertyId: propertyId,
                checkIn: timeService.createLuxonDate(new Date(activeCheckIn)).minus({ months: 1 }).toMillis(),
                checkOut: activeCheckOut,
                bookingStatus: ['confirmed', 'pending', 'new']
            },
            limit: 500,
            page: 1
        }
    }, [activeCheckIn, activeCheckOut, propertyId])

    const {
        data: bookingsListData,
        // isLoading,
        // isFetching,
        error,
    } = useGetBookingsQuery({
        bookingsQuery: activeQuery.query,
        pagination: {
            page: 1,
            limit: 500,
        }
    })

    const {
        data: directBookingsData,
    } = useGetBookingsQuery({
        bookingsQuery: {
            propertyId: propertyId,
            dateBookedStart: activeCheckIn,
            dateBookedEnd: activeCheckOut,
            bookingStatus: ['confirmed', 'pending', 'new'],
            source: ['direct', 'website']
        },
        pagination: {
            page: 1,
            limit: 500,
        }
    })


    const handleDateChange = (data: any) => {
        const checkInMillis = timeService.createLuxonDate(new Date(data.checkIn)).startOf('day').toMillis()
        const checkOutMillis = timeService.createLuxonDate(new Date(data.checkOut)).endOf('day').toMillis()
        dispatch(navigateTo(`/financials-property/${propertyId}?checkIn=${checkInMillis}&checkOut=${checkOutMillis}`))
    }

    const dateHash = useMemo(() => {
        const startDate = activeCheckIn // activeQuery.query.checkIn
        const endDate = activeCheckOut // activeQuery.query.checkOut
        const tempDateHash: { [d: string]: any } = timeService.createDateHashObject(startDate, endDate)
        return tempDateHash
    }, [activeCheckIn, activeCheckOut])

    const previousDateHash = useMemo(() => {
        const startDate = timeService.createLuxonDate(new Date(activeQuery.query.checkIn)).minus({ months: 1 }).toMillis()
        const endDate = timeService.createLuxonDate(new Date(startDate)).endOf('month').toMillis()
        const tempDateHash: { [d: string]: any } = timeService.createDateHashObject(startDate, endDate)
        return tempDateHash
    }, [activeQuery])


    const {
        nightlyBookingData,
        fullBookingList,
        hashedBookingList,
    } = useMemo(() => {
        const bookingsList = bookingsListData?.docs || []
        const directBookings = directBookingsData?.docs || []
        const fullBookingList = [...(bookingsList ? bookingsList : []), ...(directBookings ? directBookings : [])]
        const hashedData: any = bookingsByDayObject(fullBookingList)

        const hashedBookingList: any = {}

        fullBookingList.forEach((x: any) => {
            hashedBookingList[x._id] = x
        })

        return {
            nightlyBookingData: hashedData,
            fullBookingList,
            hashedBookingList,
        }
    }, [bookingsListData, directBookingsData])

    const rangeSummary = useMemo(() => {
        const summaryInfo = {
            totalEarnings: 0,
            leadTime: 0,
            occupancy: 0,
            floatingOccupancy: 0,
            averageNightlyRate: 0,
            nightsRented: 0,
            nightsRemaining: 0,
            remainingEarnings: 0,
            potentialEarnings: 0,
            payout: 0,
        }

        const today = Date.now()
        Object.keys(dateHash).forEach((x: any, idx: any) => {
            const isAfterToday = timeService.dateToMillis(x) > today
            if (isAfterToday) {
                summaryInfo.nightsRemaining++
            }
            const dayInfo = nightlyBookingData[x]
            if (!dayInfo) {
                return
            }
            if (dayInfo.earnings || dayInfo.booking?._id) {
                summaryInfo.totalEarnings += dayInfo.earnings
                summaryInfo.nightsRented += 1
                if (isAfterToday) {
                    summaryInfo.nightsRemaining--
                }
            }
            if (dayInfo.leadTime) {
                summaryInfo.leadTime += dayInfo.leadTime
            }
            if (dayInfo.payout) {
                summaryInfo.payout += dayInfo.payout
            }
        })

        let prevBookings = 0
        Object.keys(previousDateHash).forEach((x: any, idx: any) => {
            const dayInfo = nightlyBookingData[x]
            if (!dayInfo) {
                return
            }
            if (dayInfo.earnings || dayInfo.booking?._id) {
                prevBookings++
            }
        })
        const prevOccupancy = Math.round((prevBookings / Object.keys(previousDateHash).length) * 100)

        summaryInfo.leadTime = Math.round(summaryInfo.leadTime / summaryInfo.nightsRented)
        summaryInfo.occupancy = Math.round((summaryInfo.nightsRented / Object.keys(dateHash).length) * 100)

        summaryInfo.floatingOccupancy = +((prevOccupancy + summaryInfo.occupancy) / 2).toFixed(2)

        summaryInfo.averageNightlyRate = +(summaryInfo.totalEarnings / summaryInfo.nightsRented).toFixed(2)
        summaryInfo.remainingEarnings = +(summaryInfo.floatingOccupancy * summaryInfo.nightsRemaining * summaryInfo.averageNightlyRate / 100).toFixed(2)
        summaryInfo.potentialEarnings = summaryInfo.totalEarnings + summaryInfo.remainingEarnings

        return summaryInfo
    }, [nightlyBookingData, dateHash, previousDateHash])

    const summaryRangeInfo = [
        [`Total Earnings`, currencyFormatter(rangeSummary.totalEarnings)],
        [`Lead Time`, `${rangeSummary.leadTime} days`],
        [`Occupancy`, `${rangeSummary.occupancy}%`],
        // [`Floating Occupancy`, `${rangeSummary.floatingOccupancy}%`],
        [`Average Nightly Rate`, currencyFormatter(rangeSummary.averageNightlyRate)],
        [`Nights Rented`, rangeSummary.nightsRented],
        [`Nights Remaining`, rangeSummary.nightsRemaining],
        // [`Remaining Earnings`, currencyFormatter(rangeSummary.remainingEarnings)],
        // [`Potential Earnings`, currencyFormatter(rangeSummary.potentialEarnings)],
        [`Payout`, currencyFormatter(rangeSummary.payout)],
    ]

    let lastBooking = ''
    let currentColor = 'white'

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Financials</h1>
                    {myProperties && propertyId && myProperties[propertyId] && (
                        <h3>{myProperties[propertyId].title}</h3>
                    )}
                    <hr />

                    {error && (<>{JSON.stringify(error)}<br /></>)}

                    <QuickForm
                        key={`financials-date-range-${propertyId}-${activeCheckIn}-${activeCheckOut}`}
                        initialData={{
                            dateRange: null,
                            checkIn: activeCheckIn,
                            checkOut: activeCheckOut,
                            // checkIn: rangeSelection.checkIn,
                            // checkOut: rangeSelection.checkOut,
                        }}
                        fields={[
                            {
                                fieldName: 'dateRange',
                                fieldLabel: 'Date Range',
                                fieldType: 'dateRange',
                                placeholder: 'mm/dd/yyyy - mm/dd/yyyy',
                                extra: {
                                    startDate: 'checkIn',
                                    endDate: 'checkOut',
                                }
                            },
                            {
                                fieldName: 'checkIn',
                                fieldLabel: 'Check In',
                                fieldType: 'date',
                                required: true,
                                hidden: true,
                            },
                            {
                                fieldName: 'checkOut',
                                fieldLabel: 'Check Out',
                                fieldType: 'date',
                                required: true,
                                hidden: true,
                            },
                        ]}
                        onSubmit={handleDateChange} />
                </Col>
            </Row>

            <Row>
                <Col>
                    {/* back one year */}

                    <Button
                        variant="outline-primary"
                        size={'sm'}
                        onClick={() => {
                            const startDate = timeService.createLuxonDate(new Date(activeCheckIn)).minus({ years: 1 }).toMillis()
                            const endDate = timeService.createLuxonDate(new Date(startDate)).endOf('month').toMillis()
                            handleDateChange({
                                checkIn: startDate,
                                checkOut: endDate,
                            })
                        }}
                    >
                        &larr; Year
                    </Button>
                    &nbsp;
                    {/* back one month */}
                    <Button
                        variant="outline-primary"
                        size={'sm'}
                        onClick={() => {
                            const startDate = timeService.createLuxonDate(new Date(activeCheckIn)).minus({ months: 1 }).startOf('month').toMillis()
                            const endDate = timeService.createLuxonDate(new Date(startDate)).endOf('month').toMillis()
                            handleDateChange({
                                checkIn: startDate,
                                checkOut: endDate,
                            })
                        }}
                    >
                        &larr; Month
                    </Button>
                    &nbsp;
                    {/* forward one month */}
                    <Button
                        variant="outline-primary"
                        size={'sm'}
                        onClick={() => {
                            const startDate = timeService.createLuxonDate(new Date(activeCheckIn)).plus({ months: 1 }).startOf('month').toMillis()
                            const endDate = timeService.createLuxonDate(new Date(startDate)).endOf('month').toMillis()
                            handleDateChange({
                                checkIn: startDate,
                                checkOut: endDate,
                            })
                        }}
                    >
                        Month &rarr;
                    </Button>
                    &nbsp;
                    {/* forward one year */}
                    <Button
                        variant="outline-primary"
                        size={'sm'}
                        onClick={() => {
                            const startDate = timeService.createLuxonDate(new Date(activeCheckIn)).plus({ years: 1 }).toMillis()
                            const endDate = timeService.createLuxonDate(new Date(startDate)).endOf('month').toMillis()
                            handleDateChange({
                                checkIn: startDate,
                                checkOut: endDate,
                            })
                        }}
                    >
                        Year &rarr;
                    </Button>

                </Col>
            </Row>

            <hr />
            {dateHash && Object.keys(dateHash).length > 0 && (
                <Row>
                    <Col>
                        <h2>Summary</h2>
                        <hr />
                        <ul className="summary-range-info">
                            {summaryRangeInfo.map((x: any, idx: any) => (
                                <li key={idx}>
                                    <div>
                                        {x[0]}:
                                    </div>
                                    <div>
                                        {x[1]}
                                    </div>
                                </li>
                            ))}
                        </ul>

                        <hr />
                        <h2>
                            {dateHash[Object.keys(dateHash)[0]].month} {dateHash[Object.keys(dateHash)[0]].year} Day by Day
                        </h2>
                        <hr />
                        {Object.keys(dateHash).map((x: any, idx: number) => {
                            const nightInfo = nightlyBookingData[x]
                            const dateRef = dateHash[x]
                            const bookingId = nightInfo && nightInfo.booking && nightInfo.booking._id ? nightInfo.booking._id : ''
                            let colorChange = false
                            if (bookingId && bookingId !== lastBooking) {
                                currentColor = currentColor === '#ddd' ? '#eee' : '#ddd'
                                colorChange = true
                                // currentColor = currentColor === 'yellow' ? 'orange' : 'yellow'
                            }
                            lastBooking = bookingId

                            let nextBookingId = ''
                            if (Object.keys(dateHash)[idx + 1]) {
                                const nextNightInfo = nightlyBookingData[Object.keys(dateHash)[idx + 1]]
                                nextBookingId = nextNightInfo && nextNightInfo.booking && nextNightInfo.booking._id ? nextNightInfo.booking._id : ''
                            }


                            const payOuts = nightInfo && nightInfo.paid && Object.keys(nightInfo.paid).length > 0 ? Object.keys(nightInfo.paid) : []

                            return (
                                <div key={idx} className="night-info-wrapper" style={{
                                    backgroundColor: bookingId ? currentColor : 'white',
                                    ...(colorChange ? {
                                        marginTop: '3px',
                                        // do top left and top right border radius 5px
                                        borderTopLeftRadius: '5px',
                                        borderTopRightRadius: '5px',
                                    } : {
                                        // marginTop: '3px',
                                    }),
                                    ...(nextBookingId !== bookingId ? {
                                        borderBottomLeftRadius: '5px',
                                        borderBottomRightRadius: '5px',
                                    } : {})
                                }}>
                                    <div className="night-info-entry night-date-info">
                                        <div>
                                            {dateRef.dayOfWeek}
                                        </div>
                                        <div className="text-end">
                                            {dateRef.dayOfMonth}
                                        </div>
                                    </div>

                                    <div className="night-info-entry text-end">
                                        {nightInfo && nightInfo.earnings ? currencyFormatter(nightInfo.earnings) : '-'}
                                    </div>

                                    <div className="night-info-entry text-end small fst-italic">
                                        {nightInfo && isFinite(nightInfo.leadTime) ? `${nightInfo.leadTime > -1 ? nightInfo.leadTime : 0} days out` : '-'}
                                        {/* {nightInfo && nightInfo.leadTime > -1 ? `${nightInfo.leadTime} days out` : '-'} */}
                                    </div>

                                    <div className="night-info-entry night-details-btn-container">
                                        {colorChange && nightInfo && nightInfo.booking && nightInfo.booking._id ? (
                                            <div
                                                role="button"
                                                onClick={() => {
                                                    setBookingPreview(nightInfo)
                                                }}
                                            >
                                                👀
                                            </div>
                                        ) : (<div>-</div>)}
                                        {payOuts.map((payoutBookingId: any, idx: any) => {
                                            const payOutMatchesBookingId = payoutBookingId === bookingId
                                            const payOutBookingInfo = hashedBookingList[payoutBookingId]
                                            const source = payOutBookingInfo && payOutBookingInfo.source
                                            return (
                                                <div
                                                    className="payout-info"
                                                    key={idx}
                                                    role="button"
                                                    onClick={() => {
                                                        const bookingInfo = fullBookingList.find((x: any) => x._id === payoutBookingId)
                                                        if (bookingInfo) {
                                                            const nights = timeService.calculateNights(bookingInfo.checkIn, bookingInfo.checkOut)
                                                            // const earnings = bookingInfo.pricePerNight * nights
                                                            setBookingPreview({
                                                                booking: bookingInfo,
                                                                earnings: bookingInfo.pricePerNight,
                                                                // earnings: earnings,
                                                                nights: nights
                                                            })
                                                        }
                                                    }}
                                                >
                                                    {payOutMatchesBookingId ? '💰' : '💸'}
                                                    {source && typeof source === 'string' && bookingSourceIcons[source] && (
                                                        <>
                                                            <img src={bookingSourceIcons[source]} alt={source} className="booking-source-icon" />
                                                        </>
                                                    )}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}

                        <BookingPreview
                            booking={bookingPreview.booking}
                            onClose={() => {
                                setBookingPreview({
                                    booking: {},
                                })
                            }}
                        />

                    </Col>
                </Row>
            )}
        </Container>
    )
}