import { Button, Card, Modal } from "react-bootstrap"
import useUserRefs from "../../Hooks/UseUsersQuery/UseUserRefs"
import { useAddNewDataLogMutation, useGetDataLogsQuery } from "../../services/bClientApi"
import timeService from "../../services/time/timeService"
import { convertStringToJSON } from "../../Helpers/Strings/stringHelpers"
import { useState } from "react"
import QuickForm from "../Forms/QuickForm/QuickForm"
import { accountSelector } from "../../redux/account/accountSlice"
import { useSelector } from "react-redux"

export default function DataLogList(props: {
    dataType: string,
    dataId: string,
}) {

    const baseUserInfo = useSelector(accountSelector)
    const { _id } = baseUserInfo

    const userRef = useUserRefs()

    const [loadData, setLoadData] = useState(false)
    const [addNoteMode, setAddNoteMode] = useState(false)

    const {
        dataType,
        dataId,
    } = props

    const [
        addNewDataLog,
    ] = useAddNewDataLogMutation()

    const {
        data,
        error,
        isLoading,
        isFetching,
    } = useGetDataLogsQuery({
        dataLogsQuery: {
            dataType,
            dataId,
        },
        pagination: {
            limit: 10,
            page: 1,
        }
    }, { skip: !dataType || !dataId || !loadData })

    const dataLogList = data?.docs || []

    const handleAddNote = async (note: string) => {
        setAddNoteMode(false)
        const newNoteEntry = {
            note,
            dataType,
            dataId,
            logType: 'Note',
            createdDate: new Date(),
            createdBy: _id ? _id : 'unidentified',
            data: '{}',
        }

        await addNewDataLog(newNoteEntry).unwrap()
    }

    if (!loadData) {
        return (
            <div className="text-end">
                <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => {
                        setLoadData(true)
                    }}
                >
                    Show Data Log
                </Button>

            </div>
        )
    }

    return (
        <div>
            <h3>
                Data Log
            </h3>
            <Button
                variant="secondary"
                size="sm"
                onClick={() => {
                    // alert('Not implemented yet...')
                    setAddNoteMode(true)
                }}
                className="mb-2"
            >
                Add Note
            </Button>

            {isLoading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}
            {isFetching && <p>Fetching...</p>}

            {!isLoading && !isFetching && !error && dataLogList.length === 0 && (
                <p>No entries found...</p>
            )}

            {dataLogList.map((dataLogEntry: any, idx: number) => {
                const {
                    createdBy,
                    createdDate,
                    // dataId,
                    dataType,
                    logType,
                    note,
                    data,
                } = dataLogEntry

                const parsedData = convertStringToJSON(data)

                return (
                    <Card key={idx} className="mb-2 small p-2">
                        <Card.Body className="p-2">
                            <p className="mb-2">
                                <span className="fst-italic">
                                    {timeService.getFormattedTime(createdDate)}
                                </span>

                                <br />
                                <b>

                                    {userRef && userRef[createdBy] ?
                                        (<>
                                            {userRef[createdBy].firstName + ' ' + userRef[createdBy].lastName}
                                        </>) : (
                                            <>
                                                {createdBy}
                                            </>
                                        )}
                                </b>
                                <br />
                                <span className="text-muted text-capitalize">
                                    {dataType} {logType}
                                </span>
                                <br />
                            </p>
                            {note && (
                                <p className="fst-italic keep-whitespace mb-2">
                                    {note}
                                </p>
                            )}

                            {parsedData && (
                                <>
                                    {parsedData.diffs && parsedData.diffs.length > 0 && (
                                        <>
                                            {parsedData.diffs.map((diff: any, idx: number) => (
                                                <pre key={idx} className="mb-2 border-bottom pb-2">
                                                    {diff.type}: {diff.path.join('.')} <br />
                                                    {diff.oldValue && (
                                                        <>
                                                            <b>Old:</b> {JSON.stringify(diff.oldValue)}
                                                            <br />
                                                        </>
                                                    )}
                                                    {diff.value && (
                                                        <>
                                                            <b>New:</b> {JSON.stringify(diff.value)}
                                                        </>
                                                    )}
                                                </pre>
                                            ))}
                                        </>
                                    )}

                                </>
                            )}
                        </Card.Body>

                    </Card>
                )
            })}

            <Modal
                show={addNoteMode}
                onHide={() => {
                    setAddNoteMode(false)
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Add Note
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-lg font-semibold">
                        Enter your note below.
                    </p>

                    <QuickForm
                        initialData={{
                            note: '',
                        }}
                        fields={[
                            {
                                fieldLabel: 'Note',
                                fieldName: 'note',
                                fieldType: 'textarea',
                                required: true,
                            }
                        ]}
                        onSubmit={(formData) => {
                            console.log('Form data:', formData)
                            handleAddNote(formData.note)
                        }}
                        onCancel={() => {
                            setAddNoteMode(false)
                        }}
                    />

                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="primary" onClick={() => { }}>
                        Confirm
                    </Button>
                    <Button variant="secondary" onClick={() => { setAddNoteMode(false) }}>
                        Cancel
                    </Button>
                </Modal.Footer> */}

            </Modal>


        </div>
    )
}