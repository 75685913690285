import { useSelector } from "react-redux";
import { counterSelector, hideMessage } from "../../redux/counter/counterSlice";
import { useAppDispatch } from "../../redux/store";
import { useEffect } from "react";
import { Toast, ToastContainer } from "react-bootstrap";

export default function QuickMessage() {
    const {
        title,
        message,
        active,
        variant
    } = useSelector(counterSelector)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!active) { return }
        const timeout = setTimeout(() => {
            dispatch(hideMessage())
        }, 1500)
        return () => clearTimeout(timeout)
    }, [active, message, dispatch])

    // if(!active) { return null }

    return (
        <div className="message-toast-fix">
            <ToastContainer>
                <Toast
                    bg={variant ? variant : 'light'}
                    className={containerClasses[variant ? variant : 'light'] ? containerClasses[variant ? variant : 'light'] : ''}
                    show={active}
                >
                    {title && (
                        <Toast.Header>
                            <strong className="me-auto">
                                {title}
                            </strong>
                        </Toast.Header>
                    )}
                    {message && (
                        <Toast.Body>
                            {message}
                        </Toast.Body>
                    )}
                </Toast>
            </ToastContainer>
        </div>
    )
}

const containerClasses: {
    [key: string]: string
} = {
    'primary': 'text-light',
    'secondary': 'text-light',
    'success': 'text-light',
    'danger': 'text-light',
    'warning': 'text-dark',
    'info': 'text-light',
    'light': 'text-dark',
    'dark': 'text-light',
}