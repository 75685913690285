// export const SERVER_URL = 'https://rocko-lee.herokuapp.com';
export const SERVER_URL = 'https://brocko-lee.benichaybrothers.com';
// export const SERVER_URL = 'http://127.0.0.1:3008';
// export const SERVER_URL = 'http://192.168.1.204:3008';

export const API_URL = `${SERVER_URL}/api`;

export const FONTAINE_PUPPET_BASE_URL = 'https://fontaine-puppet.herokuapp.com'
// export const FONTAINE_PUPPET_BASE_URL = 'http://localhost:3420'
export const FONTAINE_PUPPET_CHECK_AVAILABILITY = `${FONTAINE_PUPPET_BASE_URL}/puppet-fontaine/check-availability`
export const FONTAINE_PUPPET_CHECK_BOOKINGS = `${FONTAINE_PUPPET_BASE_URL}/puppet-fontaine/check-bookings`
export const FONTAINE_PUPPET_CHECK_BLACKOUTS = `${FONTAINE_PUPPET_BASE_URL}/puppet-fontaine/blackout-dates`
export const FONTAINE_PUPPET_VALIDATE_BOOKING = `${FONTAINE_PUPPET_BASE_URL}/puppet-fontaine/validate-bookings`

export const BENICHAY_RESERVES_BASE_URL = 'https://benichay-reserves.herokuapp.com'

// YY.MM.DD.version
export const BCLIENT_APP_VERSION = '24.04.02.1005-BONS'

export const FRONTEND_URL = 'https://www.benichaybrothers.com'
// export const FRONTEND_URL = 'http://localhost:3009'
// export const FRONTEND_URL = 'http://192.168.1.204:3009'


// CHECK IF PRIME REGEX
//  /^1?$|^(11+?)\1+$/