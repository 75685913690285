import { useState } from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { useParams } from "react-router"
import { BookingEntry } from "../../../Components/Bookings/BookingEntry/BookingEntry"
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook"
import { useAppDispatch } from "../../../redux/store"
import BookingGuestEntry from "../../../Components/BookingGuests/BookingGuestEntry/BookingGuestEntry"
import PurchaseEntry from "../../../Components/Purchases/PurchaseEntry"
import { getChildProperties } from "../../../Helpers/Property/multiUnit"
import canAccess from "../../../services/accessService/accessService"
import AccessClickButton from "../../../Components/Buttons/AccessClickButton"
import BookingModal, { useBookingModal } from "../../../Components/Bookings/BookingModal/BookingModal"
import { FRONTEND_URL } from "../../../Config/config"
import { convertStringToJSON } from "../../../Helpers/Strings/stringHelpers"
import { ConfirmModal } from "../../../Components/Modals/confirmModal"
import { showError, showSuccess } from "../../../redux/counter/counterSlice"
import { useAddNewBukinMutation, useAddNewDateBlockMutation, useEditBookingMutation, useGetBookingByIdQuery, useGetBookingGuestsQuery, useGetPurchasesQuery, useLazyGetBukiblesQuery, useLazyGetBukinsQuery, useSendBookingConfirmedEmailMutation } from "../../../services/bClientApi"
import { airBnbReservationDetailsLink } from "../../../Helpers/Booking/airbnbReservationDetails"
import { Link } from "react-router-dom"
import DataLogList from "../../../Components/DataLogs/DataLogList"

export default function BookingPage() {
  const [addNewDateBlock] = useAddNewDateBlockMutation()

  const [addNewBukin] = useAddNewBukinMutation()

  const [
    findBukibles,
    // { isLoading: bukiblesLoading, error: bukiblesError }
  ] = useLazyGetBukiblesQuery()

  const [
    findBukins,
    // { isLoading: bukinsLoading, error: bukinsError }
  ] = useLazyGetBukinsQuery()

  const [
    sendBookingConfirmedEmail,
    {
      error: sendBookingConfirmedEmailError,
      isLoading: sendBookingConfirmedEmailLoading,
    }
  ] = useSendBookingConfirmedEmailMutation()

  const { myProperties } = useMyProperties()
  const { bookingId = '' } = useParams()

  const {
    data,
    // isLoading,
    error,
  } = useGetBookingByIdQuery(bookingId)
  const bookingData = data || {}

  const [editBooking] = useEditBookingMutation()


  const dispatch = useAppDispatch()

  const [sendBookingEmailModal, setSendBookingEmailModal] = useState(false)

  const [purchaseParkingModal, setPurchaseParkingModal] = useState(false)

  const propertyData = myProperties[bookingData?.propertyId] || {}

  const {
    data: bookingGuestData,
  } = useGetBookingGuestsQuery({
    bookingGuestsQuery: {
      bookingId: bookingId,
    },
    pagination: {
      page: 1,
      limit: 1,
    }
  }, {
    skip: !bookingId
  })

  const bookingGuestsList = bookingGuestData?.docs || []

  const bookingGuestInfo = bookingGuestsList && bookingGuestsList[0] && bookingGuestsList[0].bookingId === bookingId ? bookingGuestsList[0] : null

  const bookingGuestLink = `${FRONTEND_URL}/guest/confirm-booking?sourceConfirmation=${bookingData.sourceConfirmation}`
  const bookingDetailsLink = `${FRONTEND_URL}/booking-details/${bookingId}`

  const purchaseRefId = bookingData?.sourceConfirmation

  const {
    data: purchaseData,
    // isLoading: purchasesLoading,
    // error: purchasesError,
  } = useGetPurchasesQuery({
    purchasesQuery: {
      // productId: bookingId
      referenceNumber: purchaseRefId,
      sessionStatus: 'complete',
    },
    pagination: {
      limit: 100,
      page: 1,
    }
  }, {
    skip: !purchaseRefId
  })
  const purchasesList = purchaseData?.docs || []

  let purchaseInfo: any[] = []

  if (purchasesList && purchasesList[0] && purchasesList[0].products && purchasesList[0].products.length > 0) {
    purchaseInfo = purchasesList
  }

  const {
    editBookingModal,
    clearBookingModal,
    onEditClick,
    onCheckOutClick,
    bookingModalonSubmit
  } = useBookingModal()


  let emailAddress = ''

  if (purchaseInfo && purchaseInfo[0] && purchaseInfo[0].email) {
    emailAddress = purchaseInfo[0].email
  }
  if (!emailAddress && bookingData?.contactInfo?.email) {
    emailAddress = bookingData.contactInfo.email
  }

  let parkingSettings: {
    parkingType: string,
    parkingGroups: string[],
    parkingCost: number,
  } = {
    parkingType: '',
    parkingGroups: [],
    parkingCost: 0,
  }

  const hasChildren = getChildProperties(myProperties, bookingData?.propertyId).length > 0
  const isFontaine = propertyData?.title?.toLowerCase().indexOf('fontaine') > -1
  let usingBlocks = !hasChildren && isFontaine

  if (propertyData?.linkedServices) {
    propertyData.linkedServices.forEach((service: any) => {
      const {
        linkedService,
        linkedServiceId,
      } = service

      if (usingBlocks && linkedService === 'fontainePortal') {
        try {
          const fontainePortalCreds = convertStringToJSON(linkedServiceId)
          const {
            skipValidation
          } = fontainePortalCreds
          if (skipValidation) {
            usingBlocks = false
          }
        } catch (error) { }
      }

      if (linkedService === 'parking') {
        parkingSettings = convertStringToJSON(linkedServiceId)
      }

    })
  }

  const cancelAndBlock = async () => {
    console.log('cancel and block')
    const newBlock = {
      created: new Date(),
      checkIn: bookingData.checkIn,
      checkOut: bookingData.checkOut,
      firstName: bookingData.contactInfo.firstName,
      lastName: bookingData.contactInfo.lastName,
      propertyId: bookingData.propertyId,
      confirmationCode: bookingData.confirmationCode,
      source: 'owner',
      status: 'confirmed',
      tags: []
    }
    console.log('current booking', bookingData)
    const updatedBooking = await editBooking({
      bookingId: bookingData._id,
      bookingInfo: {
        bookingStatus: 'canceled'
      }
    }).unwrap()
    console.log('updatedBooking', updatedBooking)

    console.log('newBlock', newBlock)
    const newDateBlock = await addNewDateBlock(newBlock).unwrap()
    console.log('newDateBlock', newDateBlock)
  }

  const beforeCheckout = bookingData && bookingData.checkOut && new Date(bookingData.checkOut) > new Date()
  const afterCheckin = bookingData && bookingData.checkIn && new Date(bookingData.checkIn) < new Date()
  const checkedIn = beforeCheckout && afterCheckin

  let remoteLockPayload: {
    userId?: string,
    pin?: string,
    checkIn?: string,
    checkOut?: string,
  } = {}

  const parkingEntries: {
    productId: string,
    bukibleId: string,
    bukinId: string,
    parkingStart: string,
    parkingEnd: string,
  }[] = []

  if (bookingData && bookingData.linkedServices && bookingData.linkedServices.length > 0) {
    bookingData.linkedServices.forEach((service: any) => {
      if (service.linkedService === 'remoteLock') {
        remoteLockPayload = convertStringToJSON(service.linkedServiceId)
        return
      }

      if (service.linkedServiceId) {
        const linkedServicePayload = convertStringToJSON(service.linkedServiceId)
        // find parking entries
        if (linkedServicePayload && linkedServicePayload?.productId === 'parking') {
          parkingEntries.push(linkedServicePayload)
        }
      }

    })
  }

  const airbnbReservationDetails = airBnbReservationDetailsLink(bookingData)

  return (
    <Container>
      <Row>
        <Col
          xs={12}
          lg={9}
        >
          <h1>Booking Information</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {sendBookingConfirmedEmailError && (<>{JSON.stringify(sendBookingConfirmedEmailError)}<br /></>)}

          {bookingId && bookingData && (
            <div className="booking-info pb-4">
              <BookingEntry
                bookingInfo={bookingData}
                propertyName={propertyData?.title}
                options={{
                  details: true,
                  disableLinks: {
                    view: true,
                    delete: true,
                  }
                }}
                extraButtons={[
                  ...(
                    canAccess('routes', 'edit-booking') ?
                      [
                        {
                          label: 'Edit🕒',
                          variant: 'primary',
                          onClick: () => onEditClick(bookingData)
                        },
                        ...(checkedIn ? [{
                          label: 'Check Out⚡',
                          variant: 'secondary',
                          onClick: () => {
                            onCheckOutClick(bookingData)
                          }
                        }] : [])
                      ] : []
                  ),

                  ...(
                    beforeCheckout &&
                      canAccess('routes', 'edit-booking') &&
                      canAccess('routes', 'add-date-block') &&
                      bookingData.bookingStatus === 'confirmed' &&
                      bookingData.confirmationCode &&
                      usingBlocks ? [
                      {
                        label: 'Cancel and Block',
                        variant: 'warning',
                        onClick: cancelAndBlock,
                      }
                    ] : [])
                ]}
              />

              {remoteLockPayload.userId && remoteLockPayload.pin && (
                <>
                  <h3>Remote Lock Information</h3>
                  <p>
                    <b>Remote Lock User Id:</b> <a href={`https://connect.remotelock.com/access/user/${remoteLockPayload.userId}`} target="_blank" rel="noreferrer">{remoteLockPayload.userId}</a><br />
                    <b>Remote Lock Pin:</b> {remoteLockPayload.pin}<br />
                  </p>
                </>
              )}


              {bookingData && bookingData.notes && (
                <>
                  <h3>Booking Notes</h3>
                  <p className="keep-whitespace">
                    {bookingData.notes}
                  </p>
                  <br />
                </>
              )}

              {bookingData.source === 'direct' || bookingData.source === 'website' ? (
                <>
                  <h3>Booking Related Functions</h3>
                  {emailAddress && (
                    <>
                      <b>Email Address:</b> {emailAddress}<br />
                      <Button
                        className={"mb-2"}
                        variant="primary"
                        disabled={sendBookingConfirmedEmailLoading}
                        onClick={async () => {
                          setSendBookingEmailModal(true)
                        }}
                      >
                        Send Booking Confirmation Email
                      </Button>
                      <br />

                      <ConfirmModal
                        title="Send Booking Confirmation Email"
                        message="Are you sure you want to send the confirmation email?"
                        active={sendBookingEmailModal}
                        onClose={() => setSendBookingEmailModal(false)}
                        onConfirm={async () => {
                          setSendBookingEmailModal(false)
                          console.log('attempting to send confirmation email')
                          const mailInfo = await sendBookingConfirmedEmail(bookingId).unwrap()
                          console.log('mailInfo', mailInfo)
                          dispatch(showSuccess('Email Sent'))
                        }}
                      />


                    </>
                  )}

                  {!emailAddress && (
                    <>
                      <b>Booking does not have an email address.</b>
                      <br />
                      <br />
                    </>
                  )}
                </>
              ) : null}

              {canAccess('routes', 'booking-guest') && (
                <>
                  <h3>Booking Guest Link</h3>
                  <a href={bookingGuestLink} target="_blank" rel="noreferrer" className="mb-2">
                    {bookingGuestLink}
                  </a>
                  <br /> <br />
                  {canAccess('routes', 'edit-booking') && bookingGuestInfo && !bookingData.bookingGuestInfoId && (
                    <>
                      <AccessClickButton
                        variant="primary"
                        text="Link Guest Info"
                        resource="routes"
                        action="edit-booking"
                        onClick={() => {
                          editBooking({
                            bookingId: bookingData._id,
                            bookingInfo: {
                              bookingGuestInfoId: bookingGuestInfo._id,
                            }
                          })
                        }}
                      />
                    </>
                  )}
                </>
              )}

              {canAccess('booking', 'airbnbReservationDetails') && airbnbReservationDetails && (
                <>
                  <h3>Airbnb Reservation Details</h3>
                  <a href={airbnbReservationDetails} target="_blank" rel="noreferrer" className="mb-2">
                    {airbnbReservationDetails}
                  </a>
                  <br />
                </>
              )}
            </div>
          )}

          {canAccess('routes', 'edit-booking') && canAccess('routes', 'edit-bukin') && parkingSettings && parkingSettings?.parkingGroups?.length > 0 && (
            <div className="mb-4">
              <h3>Parking Is Offered</h3>
              <ConfirmModal
                title="Purchase Parking"
                message="Would you like to purchase parking?"
                active={purchaseParkingModal}
                onClose={() => setPurchaseParkingModal(false)}
                onConfirm={async () => {
                  setPurchaseParkingModal(false)
                  // console.log('purchase parking')

                  const bukibleKeys: { [key: string]: { data: any, quantity: number } } = {}
                  const availableBukiblesResponse = await findBukibles({
                    bukiblesQuery: {
                      groups: parkingSettings.parkingGroups ? parkingSettings.parkingGroups : ['default'],
                    },
                    pagination: {
                      limit: 100,
                      page: 1
                    }
                  })
                  const availableBukibles = availableBukiblesResponse?.data || {}
                  // console.log('availableBukibles', availableBukibles)
                  if (availableBukibles && availableBukibles?.docs?.length > 0) {
                    availableBukibles?.docs?.forEach((bukible: any) => {
                      bukibleKeys[bukible._id] = {
                        data: { ...bukible },
                        quantity: bukible.quantity ? bukible.quantity : 0
                      }
                    })
                  }
                  // console.log('bukibleKeys', bukibleKeys)

                  const bukinsInRangeResponse = await findBukins({
                    bukinsQuery: {
                      checkIn: bookingData.checkIn,
                      checkOut: bookingData.checkOut,
                      bukbleId: [...Object.keys(bukibleKeys)],
                      status: ['new', 'confirmed', 'pending', 'request']
                    },
                    pagination: {
                      limit: 100,
                      page: 1
                    }
                  })
                  const bukinsInRange = bukinsInRangeResponse?.data || {}
                  if (bukinsInRange && bukinsInRange.docs.length > 0) {
                    bukinsInRange.docs.forEach((bukin: any) => {
                      if (bukin.bukibleId) {
                        if (bukibleKeys[bukin.bukibleId]) {
                          bukibleKeys[bukin.bukibleId].quantity -= 1
                          if (bukibleKeys[bukin.bukibleId].quantity <= 0) {
                            delete bukibleKeys[bukin.bukibleId]
                          }
                        }
                      }
                    })
                  }

                  if (Object.keys(bukibleKeys).length === 0) {
                    dispatch(showError('No parking available'))
                    return {
                      error: 'No parking available',
                    }
                  }


                  const firstBukibleId = Object.keys(bukibleKeys)[0]
                  // const bukibleEntry = bukibleKeys[firstBukibleId].data
                  // console.log('bukibleEntry', bukibleEntry)
                  const newBukin: any = {
                    checkIn: bookingData.checkIn,
                    checkOut: bookingData.checkOut,
                    bukibleId: firstBukibleId,
                    referenceNumber: bookingData.sourceConfirmation,
                    bookingId: bookingData._id,
                    earnings: 0,
                    contactInfo: {
                      firstName: bookingData?.contactInfo?.firstName,
                      lastName: bookingData?.contactInfo?.lastName,
                      email: bookingData?.contactInfo?.email,
                      phone: bookingData?.contactInfo?.phone,
                    },
                    status: 'confirmed',
                    notes: '',
                  }

                  const createdBukin = await addNewBukin(newBukin).unwrap()

                  // console.log('createdBukin', createdBukin)

                  if (!createdBukin || !createdBukin._id) {
                    dispatch(showError('Error creating bukin'))
                    return {
                      error: 'Error creating bukin'
                    }
                  }

                  const linkedServiceEntry = {
                    linkedService: `parking-${firstBukibleId}-${createdBukin._id}`,
                    linkedServiceId: JSON.stringify({
                      productId: 'parking',
                      bukibleId: firstBukibleId,
                      bukinId: createdBukin._id,
                      parkingStart: bookingData.checkIn,
                      parkingEnd: bookingData.checkOut,
                    }),
                  }

                  const updatedBooking = await editBooking({
                    bookingId: bookingData._id,
                    bookingInfo: {
                      linkedServices: [
                        ...bookingData.linkedServices,
                        linkedServiceEntry
                      ]
                    }
                  }).unwrap()

                  // console.log('updatedBooking', updatedBooking)

                  if (!updatedBooking || !updatedBooking._id) {
                    dispatch(showError('Error updating booking'))
                    return {
                      error: 'Error updating booking'
                    }
                  }

                  dispatch(showSuccess('Parking Purchased'))

                }}
              />
              <Button
                variant="primary"
                onClick={() => setPurchaseParkingModal(true)}
              >
                Give Parking
              </Button>

            </div>
          )}

          {parkingEntries && parkingEntries.length > 0 && (
            <>
              <h3>Parking Information</h3>
              {parkingEntries.map((entry, idx: number) => (
                <div key={`parking-entry-${idx}`}>
                  <b>Parking Start:</b> {entry.parkingStart}<br />
                  <b>Parking End:</b> {entry.parkingEnd}<br />
                  <b>Bukible Id:</b> {entry.bukibleId}<br />
                  {/* <b>Bukible Id:</b> <Link to={`/bukibles/${entry.bukibleId}`}>  {entry.bukibleId}</Link> <br /> */}
                  <b>Bukin Id:</b> {canAccess('routes', 'bukin') ? (<Link to={`/bukin/${entry.bukinId}`}>{entry.bukinId}</Link>) : `${entry.bukinId}`} <br />
                </div>
              ))}
            </>
          )}


          {canAccess('routes', 'booking-guest') && bookingGuestInfo && (
            <>
              <h3>Booking Details Page</h3>
              <a href={bookingDetailsLink} target="_blank" rel="noreferrer" className="mb-2">
                {bookingDetailsLink}
              </a>

              <div className="booking-guest-info">
                <h3>Guest Info</h3>
                <BookingGuestEntry
                  bookingGuestInfo={bookingGuestInfo}
                  propertyData={propertyData}
                />
              </div>
            </>
          )}

          {canAccess('routes', 'purchase') && purchaseInfo && purchaseInfo.length > 0 && (
            <>
              <h3>Purchase Info</h3>
              {purchaseInfo.map((purchase) => (
                <PurchaseEntry
                  key={purchase._id}
                  purchaseData={purchase}
                />
              ))}
            </>
          )}
        </Col>

        <Col
          xs={12}
          lg={3}
        >
          <DataLogList
            dataType="booking"
            dataId={bookingId}
          />
        </Col>
      </Row>

      <BookingModal
        active={editBookingModal.active}
        booking={editBookingModal.booking}
        clearBookingModal={clearBookingModal}
        bookingModalonSubmit={bookingModalonSubmit}
      />
    </Container>
  )
}
