import { useState } from "react"
import { API_URL } from "../../Config/config"
import { useAppDispatch } from "../../redux/store"
import { showError, showSuccess } from "../../redux/counter/counterSlice"
// import { useUploadImageMutation } from "../../services/bClientApi"

export default function useUploadImage() {

    const dispatch = useAppDispatch()

    // const [
    //     uploadImage,
    //     {
    //         error,
    //         isLoading
    //     }
    // ] = useUploadImageMutation() 

    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [imageUploadProgress, setImageUploadProgress] = useState('')

    const uploadImageFn = async (imageInfo: { imageToUpload: any, filename: string }) => {

        let uploadedImageUrl = ''
        setIsLoading(true)
        setImageUploadProgress(`Processing...`)
        setError('')

        try {
            // const uploadedImageUrl = await uploadImage(imageInfo).unwrap()
            // return uploadedImageUrl.imageUrl

            // uploadedImageUrl = await uploadImage(imageInfo)
            uploadedImageUrl = await uploadSignedImage(imageInfo)

            dispatch(showSuccess(`Image uploaded successfully`))
        } catch (error) {
            console.log(`Error uploading image: ${error}`)
            setError(`Error uploading image: ${error}`)
            dispatch(showError(`Error uploading image: ${error}`))
        }

        setIsLoading(false)
        setImageUploadProgress(``)
        return uploadedImageUrl
    }

    const uploadImageMultiFn = async (imageInfo: { imageToUpload: any, filename: string }[]) => {
        const uploadedImageUrls = []
        setIsLoading(true)
        setImageUploadProgress(`Processing...`)
        setError('')

        try {
            for (let i = 0; i < imageInfo.length; i++) {
                // const uploadedImageUrl = await uploadImage(imageInfo[i]).unwrap()
                // uploadedImageUrls.push(uploadedImageUrl.imageUrl)
                setImageUploadProgress(`Processing ${i + 1} of ${imageInfo.length}`)
                // const uploadedImageUrl = await uploadImage(imageInfo[i])
                const uploadedImageUrl = await uploadSignedImage(imageInfo[i])
                uploadedImageUrls.push(uploadedImageUrl)
            }
            dispatch(showSuccess(`Images uploaded successfully`))
        } catch (error) {
            console.log(`Error uploading images: ${error}`)
            setError(`Error uploading images: ${error}`)
            dispatch(showError(`Error uploading images: ${error}`))
        }

        setIsLoading(false)
        setImageUploadProgress(``)
        return uploadedImageUrls
    }


    return {
        uploadImageFn,
        uploadImageMultiFn,
        imageUploadProgress,
        error,
        isLoading
    }
}


export async function uploadImage(uploadImageInfo: { imageToUpload: any, filename: string }) {
    const { imageToUpload, filename } = uploadImageInfo
    imageToUpload.lastModifiedDate = new Date()
    imageToUpload.name = filename
    const formData = new FormData()
    formData.append('image', imageToUpload, filename)
    const uploadedImageData = await fetch(`${API_URL}/upload/image-upload`, {
        method: 'POST',
        body: formData,
        cache: 'no-store'
    }).then((res) => {
        return res.json()
    }).catch(err => {
        return {
            error: err
        }
    })
    if (uploadedImageData && uploadedImageData.imageUrl) {
        return uploadedImageData.imageUrl
    }
    if (uploadedImageData && uploadedImageData.error) {
        throw new Error(uploadedImageData.error)
    }
    return ''
}

export async function uploadSignedImage(uploadImageInfo: { imageToUpload: any, filename: string }) {

    const splitImageName = uploadImageInfo.filename.split('.')
    if (splitImageName.length > 1) {
        splitImageName.pop()
    }

    const fileName = splitImageName.join('.')
    const fileType = uploadImageInfo.imageToUpload.type

    const splitFileType = fileType.split('/')
    let fileExtension = ''
    if (splitFileType.length > 1) {
        fileExtension = splitFileType[splitFileType.length - 1]
    }

    const s3SigningUrl = `${API_URL}/upload/sign-s3?file-name=${fileName}-${Date.now()}${fileExtension ? '.' + fileExtension : ''}&file-type=${fileType}`

    const signedUrl = await fetch(s3SigningUrl, {
        method: 'GET',
        cache: 'no-store'
    }).then((res) => {
        return res.json()
    }).catch(err => {
        return {
            error: err
        }
    })

    if (signedUrl && signedUrl.signedUrl) {
        const fileUrl = signedUrl.signedUrl.split('?X-Amz')[0]
        // console.log(`signedUrl: ${signedUrl.signedUrl}`)
        let errorUploading = ''
        await fetch(signedUrl.signedUrl, {
            // const signedUrlResponse = await fetch(signedUrl.signedUrl, {
            method: 'PUT',
            body: uploadImageInfo.imageToUpload,
            headers: {
                'Content-Length': uploadImageInfo.imageToUpload.size.toString()
            },
            // cache: 'no-store'
        }).then((res) => {
            return res
        }).catch(err => {
            // errorUploading = err
            errorUploading = `${uploadImageInfo.filename} - ${err}`
            return {
                error: err
            }
        })

        if (errorUploading) {
            throw new Error(`Error uploading file: ${errorUploading}`)
        }

        // check if the file was uploaded
        const checkFile: any = await fetch(fileUrl, {
            method: 'HEAD',
            cache: 'no-store'
        }).then((res) => {
            return res
        }).catch(err => {
            return {
                error: err
            }
        })

        if (checkFile && checkFile.status !== 200) {
            throw new Error(`File not uploaded: ${checkFile.status}`)
        }

        // if (!signedUrlResponse.error || Object.keys(signedUrlResponse.error).length === 0) {
        return fileUrl
        // }

        // if (signedUrlResponse.error) {
        //     throw new Error(signedUrlResponse.error)
        // }
    }

    if (signedUrl && signedUrl.error) {
        // throw new Error(signedUrl.error)
        throw new Error(`Error getting signed url: ${signedUrl.error}`)
    }

    return ''
}