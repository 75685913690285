import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import { useState } from "react";
import useUserRefs from "../../../Hooks/UseUsersQuery/UseUserRefs";
import './selectUserModal.scss';

export default function SelectUserModal(props: {
    active: boolean,
    onClose: () => void,
    onSelect: (userData: any) => void,
    message?: string,
    title?: string
}) {
    const {
        active,
        onClose,
        onSelect,
        message = "Please select a user",
        title = "Choose User"
    } = props;

    const userRefs = useUserRefs();
    const [quickFilter, setQuickFilter] = useState('');
    const [selectedUser, setSelectedUser] = useState('');

    const usersInList: any = {}
    const filteredUsers = Object.keys(userRefs).filter((userId: string) => {
        const user = userRefs[userId];
        if (quickFilter &&
            !JSON.stringify(user).toLowerCase().includes(quickFilter.toLowerCase())
        ) {
            return false;
        }
        if (usersInList[user._id]) {
            return false;
        }
        usersInList[user._id] = true;
        return true;
    });

    return (
        <Modal
            show={active}
            onHide={onClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="text-lg font-semibold mb-4">{message}</p>

                <Form.Group className={`mb-3`}>
                    <FloatingLabel label={`Filter Users`}>
                        <Form.Control
                            type="text"
                            placeholder={'Filter Users'}
                            value={quickFilter}
                            onChange={(e: any) => setQuickFilter(e.target.value)}
                        />
                    </FloatingLabel>
                </Form.Group>

                {filteredUsers.length === 0 && (
                    <p>No users found</p>
                )}

                {filteredUsers.length > 0 && (
                    <div className="select-user-list-container">
                        {filteredUsers.map((userId: string) => (
                            <div
                                key={`user-${userId}`}
                                className={`select-user-entry ${selectedUser === userId ? 'selected' : ''}`}
                                onClick={() => setSelectedUser(userId)}
                            >
                                ({userRefs[userId].email}) - {userRefs[userId].firstName} {userRefs[userId].lastName}
                            </div>
                        ))}
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    disabled={!selectedUser}
                    onClick={() => {
                        if (selectedUser) {
                            onSelect(userRefs[selectedUser]);
                            onClose();
                        }
                    }}
                >
                    Select User
                </Button>

                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
}