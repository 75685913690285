import { useFormikContext } from "formik"
import AddressInputField from "../AddressInput/AddressInputField"
import { CheckBoxInputField } from "../CheckBoxInput/CheckBoxInputField"
import { DateInputField } from "../DateInput/DateInputField"
import { PhoneNumberInputField } from "../PhoneNumberInput/PhoneNumberInputField"
import QuickTag from "../QuickTag/QuickTag"
import { SelectInputField } from "../SelectInput/SelectInputField"
import { TextInputField } from "../TextInput/TextInputField"
import { DateRangeInputField } from "../DateInput/DateRangeInputField"
import timeService from "../../../services/time/timeService"
import ImageUploadField from "../ImageUpload/ImageUpload"
import { checkVal } from "../../../Helpers/Parsers/checkVal"

export interface FieldGeneratorFieldType {
    fieldName: string,
    fieldType: 'text' | 'textarea' | 'number' | 'password' | 'select' | 'email' | 'date' | 'dateRange' | 'checkbox' | 'imageUpload' | 'quickTag' | 'address' | 'phone' | 'custom',
    fieldLabel: string,
    placeholder?: string,
    options?: { value: string | number, text?: string }[],
    extra?: any,
    layout?: {
        xs?: number,
        sm?: number,
        md?: number,
        lg?: number,
        xl?: number,
        xxl?: number,
    },
    condition?: (values: any) => boolean,
    required?: boolean,
    hidden?: boolean,
    onChange?: (e: any) => void,
    disabled?: boolean,
    subText?: string,
    formOnChange?: (e: any, f?: any) => void,
    uploadImageFn?: (v: any) => Promise<string>,
    uploadImageMultiFn?: (v: { imageToUpload: any, filename: string }[]) => Promise<string[]>,
}

const textFieldTypes: any = {
    text: 1,
    number: 1,
    password: 1,
    email: 1,
    textarea: 1,
}

export default function FieldGenerator(props: FieldGeneratorFieldType) {
    const {
        fieldType,
        fieldLabel,
        placeholder,
        fieldName,
        subText = '',
        options,
        extra = {},
        required,
        disabled = false,
        onChange = () => { },
        formOnChange,
        uploadImageFn,
        uploadImageMultiFn,
    } = props

    const formik = useFormikContext()

    if (textFieldTypes[fieldType]) {
        return (
            <div className="field-gen-wrapper">
                <TextInputField
                    fieldType={fieldType}
                    fieldLabel={fieldLabel}
                    required={required}
                    placeholder={placeholder ? placeholder : fieldLabel}
                    fieldName={`${fieldName}`}
                    extra={extra}
                    disabled={disabled}
                    subText={subText}
                    onChange={onChange} />
            </div>
        )
    }

    if (fieldType === 'select') {
        return (
            <div className="field-gen-wrapper">
                <SelectInputField
                    fieldLabel={fieldLabel}
                    placeholder={placeholder ? placeholder : fieldLabel}
                    required={required}
                    fieldName={`${fieldName}`}
                    options={options ? options : []}
                    disabled={disabled}
                    subText={subText}
                    onChange={onChange} />
            </div>
        )
    }

    if (fieldType === 'date') {
        return (
            <div className="field-gen-wrapper">
                <DateInputField
                    fieldLabel={fieldLabel}
                    required={required}
                    placeholder={placeholder ? placeholder : fieldLabel}
                    fieldName={`${fieldName}`}
                    onChange={onChange}
                    subText={subText}
                    extraOptions={extra} />
            </div>
        )
    }

    if (fieldType === 'dateRange') {
        const {
            values,
            setValues,
            setFieldValue,
        }: any = formik
        return (
            <div className="field-gen-wrapper">
                <DateRangeInputField
                    fieldName={fieldName}
                    fieldLabel={fieldLabel}
                    startDate={values[extra.startDate]}
                    placeholder={placeholder ? placeholder : 'mm/dd/yyyy - mm/dd/yyyy'}
                    endDate={values[extra.endDate]}
                    onChange={(dates: [Date | null, Date | null]) => {
                        const startVal = timeService.setDateTime(dates[0], extra.startTime ? extra.startTime : { hour: 16 })
                        const endVal = timeService.setDateTime(dates[1], extra.endTime ? extra.endTime : { hour: 11 })
                        const newValues: any = {
                            [fieldName]: [startVal, endVal]
                        }
                        if (extra.startDate) {
                            newValues[extra.startDate] = startVal
                        }
                        if (extra.endDate) {
                            newValues[extra.endDate] = endVal
                        }
                        setValues({ ...values, ...newValues }, true)
                        if (formOnChange && dates[0] && dates[1]) {
                            formOnChange({
                                target: {
                                    name: fieldName,
                                    value: [startVal, endVal]
                                }
                            }, {
                                values: {
                                    ...values,
                                    ...newValues
                                },
                                setValues,
                                setFieldValue,
                            })
                        }
                    }} />
            </div>
        )
    }

    if (fieldType === 'checkbox') {
        return (
            <div className="field-gen-wrapper">
                <CheckBoxInputField
                    fieldLabel={fieldLabel}
                    required={required}
                    fieldName={`${fieldName}`}
                    disabled={disabled}
                    subText={subText}
                    onChange={onChange} />
            </div>
        )
    }

    if (fieldType === 'phone') {
        return (
            <div className="field-gen-wrapper">
                <PhoneNumberInputField
                    fieldName={`${fieldName}`}
                    fieldLabel={fieldLabel}
                    onChange={onChange}
                    subText={subText}
                    required={required}
                />
            </div>
        )
    }

    if (fieldType === 'address') {
        return (
            <div className="field-gen-wrapper">
                <AddressInputField
                    fieldName={fieldName}
                    required={required}
                />
            </div>
        )
    }

    if (fieldType === 'quickTag') {
        const tempFieldName = extra?.tempFieldName ? extra.tempFieldName : 'tempTag'
        const tagEntryName = extra?.tagEntryName ? extra.tagEntryName : 'tag'
        const {
            values,
            setFieldValue,
        }: any = formik
        const value: any = values[fieldName]
        return (
            <div className="field-gen-wrapper">
                <QuickTag
                    className={'mb-3'}
                    tags={value ? value : []}
                    tempTag={values[tempFieldName] ? values[tempFieldName] : ''}
                    tagFieldName={fieldName}
                    tempTagFieldName={tempFieldName}
                    tagEntryName={tagEntryName}
                    tempTagFieldLabel={fieldLabel}
                    setFieldValue={setFieldValue}
                    buttons={extra?.buttons ? extra.buttons : []}
                    options={extra?.options ? extra.options : {
                        hideInput: true,
                        hideBadges: true,
                    }}
                />
            </div>
        )
    }

    if (fieldType === 'imageUpload') {
        const multipleImages = extra?.multipleImages ? extra.multipleImages : false
        const {
            values,
            setFieldValue,
        }: any = formik
        return (
            <div className="field-gen-wrapper">
                <ImageUploadField
                    accept={extra?.accept ? extra.accept : 'image/*'}
                    multipleImages={multipleImages}
                    uploadedImageCallback={async (imageInfo: any) => {
                        try {
                            if (!multipleImages && uploadImageFn) {
                                const uploadedImageUrl = await uploadImageFn(imageInfo)
                                if (uploadedImageUrl) {
                                    setFieldValue(fieldName, uploadedImageUrl)
                                }
                            }
                            if (multipleImages && uploadImageMultiFn && extra.mapUploadedImages) {
                                const uploadedImageList = await uploadImageMultiFn(imageInfo)
                                if (uploadedImageList) {
                                    const mappedUploadedImages = extra.mapUploadedImages(uploadedImageList)
                                    const currentImages = checkVal(values, fieldName)
                                    setFieldValue(fieldName, [...(currentImages ? currentImages : []), ...mappedUploadedImages])
                                }
                            }
                        } catch (err) { }
                    }}
                />
            </div>
        )
    }

    if (fieldType === 'custom') {
        return (
            <div className="field-gen-wrapper">
                {extra?.component}
            </div>
        )
    }

    return (
        <div className="field-gen-wrapper">
            Field Type Undefined
            <br />
        </div>
    )
}