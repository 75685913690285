import { Button, FloatingLabel, Form, Modal } from "react-bootstrap"
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook"
import { useState } from "react"
import './selectPropertyModal.scss'

export function SelectPropertyModal(props: {
    active: boolean,
    onClose: () => void,
    onSelect: (propertyData: any) => void,
    message?: string
    title?: string
}) {

    const {
        active,
        onClose,
        onSelect,
        message = "Please select a property",
        title = "Choose Property"
    } = props

    const {
        myProperties,
        isLoading,
        isFetching,
    } = useMyProperties()

    const [quickFilter, setQuickFilter] = useState('')
    const [selectedProperty, setSelectedProperty] = useState('')

    const filteredProperties = Object.keys(myProperties).filter((propertyId: string) => {
        const property = myProperties[propertyId]
        if (quickFilter &&
            !JSON.stringify(property).toLowerCase().includes(quickFilter.toLowerCase())
        ) {
            return false
        }
        return true
    })

    return (
        <Modal
            show={active}
            onHide={onClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading && <p>Loading...</p>}
                {isFetching && <p>Fetching...</p>}
                <p className="text-lg font-semibold mb-4">{message}</p>

                <Form.Group className={`mb-3`}>
                    {/*<Form.Label>{fieldLabel}{required ? '*' : ''}</Form.Label>*/}
                    <FloatingLabel
                        // controlId={fieldName}
                        label={`Filter Properties`}>
                        <Form.Control
                            // type={fieldType && fieldType !== 'textarea' ? fieldType : "text"}
                            type="text"
                            placeholder={'Filter Properties'}
                            // {...(fieldType === 'textarea' ? { as: 'textarea' } : {})}
                            // name={fieldName}
                            // required={required}
                            // disabled={disabled}
                            value={quickFilter}
                            // onKeyDown={(e: any) => {
                            //     if (onKeyDown) {
                            //         onKeyDown(e)
                            //     }
                            // }}
                            onChange={(e: any) => {
                                // if (onChange) {
                                //     onChange(e)
                                // }
                                // form.handleChange(e)
                                setQuickFilter(e.target.value)
                            }}
                            // onBlur={form.handleBlur}
                            // isInvalid={checkVal(form.touched, fieldName) && checkVal(form.errors, fieldName)}
                            // isValid={!checkVal(form.errors, fieldName) && checkVal(form.touched, fieldName)}
                            // style={fieldType === 'textarea' ? { minHeight: '100px' } : {}}
                        />
                    </FloatingLabel>
                </Form.Group>

                {filteredProperties.length === 0 && (
                    <p>No properties found</p>
                )}

                {filteredProperties.length > 0 && (
                    <div className="select-property-list-container">
                        {filteredProperties.map((propertyId: string, idx) => (
                            <div
                                key={`property-${propertyId}`}
                                className={`select-property-entry ${selectedProperty === propertyId ? 'selected' : ''}`}
                                onClick={() => {
                                    setSelectedProperty(propertyId)
                                }}
                            >
                                {myProperties[propertyId].title.split(' - ')[0]}
                            </div>
                        ))}
                    </div>
                )}

            </Modal.Body>
            <Modal.Footer>

                <Button 
                variant="primary" 
                disabled={!selectedProperty}
                onClick={() => {
                    if (selectedProperty) {
                        onSelect(myProperties[selectedProperty])
                        onClose()
                    }
                }}>
                    Select Property
                </Button>

                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>

            </Modal.Footer>
        </Modal >
    )
}