import { Form, Formik } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import { checkVal } from "../../../Helpers/Parsers/checkVal";
import FieldGenerator, { FieldGeneratorFieldType } from "../../FormFields/FieldGenerator/FieldGenerator";
import "./quickForm.scss";
import { writeVal } from "../../../Helpers/Parsers/writeVal";
import { isValidPhoneNumber } from "react-phone-number-input";

export default function QuickForm(
    props: {
        fields: FieldGeneratorFieldType[] | ((v: any) => FieldGeneratorFieldType[]),
        layout?: {
            xs?: number,
            sm?: number,
            md?: number,
            lg?: number,
            xl?: number,
            xxl?: number,
        },
        initialData: any
        onSubmit?: (values: any) => void
        onChange?: (values: any, api?: any) => void
        onCancel?: () => void
        disabled?: boolean
        submitText?: string
        cancelText?: string
        showErrors?: boolean
        validationSchema?: any
        onReset?: () => any
        uploadImageFn?: (v: any) => Promise<string>,
        uploadImageMultiFn?: (v: { imageToUpload: any, filename: string }[]) => Promise<string[]>,
    }
) {
    const {
        fields,
        layout = { xs: 1 },
        initialData,
        onSubmit,
        disabled,
        submitText,
        cancelText,
        onCancel,
        onChange,
        showErrors,
        onReset,
        uploadImageFn,
        uploadImageMultiFn,
        validationSchema,
    } = props

    return (
        <Formik
            initialValues={{
                ...initialData,
            }}
            validationSchema={validationSchema ? validationSchema : undefined}
            validate={!validationSchema ? values => {
                const errors: any = {};
                const fieldList = typeof fields === 'function' ? fields(values) : fields
                fieldList.forEach(field => {
                    const fieldVal = checkVal(values, field.fieldName)
                    if (field.required && (!fieldVal || fieldVal === '')) {
                        writeVal(errors, field.fieldName, 'Required')
                    }

                    if (field.fieldType === 'phone' && fieldVal) {
                        const validPhone = isValidPhoneNumber(fieldVal)
                        if (!validPhone) {
                            writeVal(errors, field.fieldName, 'Invalid Phone Number')
                        }
                    }

                })
                return errors;
            } : undefined}
            onSubmit={async (values, { setSubmitting }) => {
                if (onSubmit) {
                    try {
                        onSubmit({ ...values })
                    } catch (err) {
                        // custom error handler
                    }
                }
                setSubmitting(false)
            }}
        >
            {({ values, isSubmitting, errors, touched, setFieldValue, handleChange, setValues }) => {
                const fieldList = typeof fields === 'function' ? fields(values) : fields
                return (
                    <Form
                        onChange={(d: any) => {
                            if (onChange) {
                                onChange(d, {
                                    values,
                                    setValues,
                                    setFieldValue,
                                })
                            }
                        }}>
                        <Row {...layout}>

                            {fieldList.map((field: FieldGeneratorFieldType, idx: number) => {
                                if (field.hidden) {
                                    return null
                                }
                                if (field.condition && !field.condition(values)) {
                                    return null
                                }
                                const fieldLayout: any = field?.layout || {}
                                const fieldVal = field.extra?.imagePreview ? checkVal(values, field.fieldName) : ''

                                return (
                                    <Col {...fieldLayout} key={`quick-form-${idx}-${field.fieldName}`}>
                                        {field.extra?.title && (
                                            <h3>{field.extra.title}</h3>
                                        )}
                                        {field.extra?.imagePreview && fieldVal && (
                                            <img className="quick-form-image-preview" alt="" src={fieldVal} />
                                        )}
                                        <FieldGenerator
                                            {...field}
                                            formOnChange={onChange}
                                            uploadImageFn={uploadImageFn}
                                            uploadImageMultiFn={uploadImageMultiFn}
                                        />
                                        {field.extra?.breakAfter && <hr />}
                                    </Col>
                                )
                            })}
                        </Row>

                        {showErrors && Object.keys(errors).length > 0 && (
                            <pre>
                                Errors: {JSON.stringify(errors)}
                            </pre>
                        )}

                        {onSubmit && (
                            <Button className="mb-3 me-3" type="submit" disabled={isSubmitting || disabled || Object.keys(errors).length > 0}>
                                {submitText ? submitText : 'Submit'}
                            </Button>
                        )}

                        {onCancel && (
                            <Button className="mb-3 me-3" type="button" variant="danger" onClick={onCancel}>
                                {cancelText ? cancelText : 'Cancel'}
                            </Button>
                        )}

                        {onReset && (
                            <Button
                                className="mb-3 me-3"
                                type="button"
                                variant="secondary"
                                onClick={() => {
                                    setValues(onReset(), true)
                                }}>
                                Reset
                            </Button>
                        )}

                    </Form>
                )
            }
            }
        </Formik>
    )

}