import { Field } from "formik"
import { FloatingLabel, Form } from "react-bootstrap"
import { checkVal } from "../../../Helpers/Parsers/checkVal"
import 'react-phone-number-input/style.css'
import './customPhoneStyles.scss'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import * as yup from 'yup'

export const PhoneNumberInputField = (props: {
    fieldName: string,
    fieldLabel: string,
    customClass?: string,
    placeholder?: string,
    required?: boolean,
    subText?: string,
    onChange?: (e: any) => void,
    extraOptions?: any
}) => {
    const {
        fieldLabel,
        customClass,
        required,
        placeholder = "Enter phone number",
        subText,
        fieldName,
        onChange,
        // extraOptions
    } = props

    return (
        <>
            <Field name={fieldName} >
                {({
                    field: { value },
                    form,
                }: any) => {
                    const errorMessage = checkVal(form.errors, fieldName)
                    const touched = checkVal(form.touched, fieldName)

                    return (
                        <Form.Group className={`mb-3 ${customClass ? customClass : ''}`}>
                            {/*<Form.Label>{fieldLabel}{required ? '*' : ''}</Form.Label>*/}
                            <FloatingLabel
                                controlId={fieldName}
                                className={'col'}
                                label={`${fieldLabel}${required ? '*' : ''}`}>

                                <PhoneInput
                                    defaultCountry="US"
                                    placeholder={placeholder}
                                    value={value}
                                    onChange={(val: any) => {
                                        if (onChange) {
                                            onChange(val)
                                        }
                                        form.setFieldTouched(fieldName, true, true)
                                        form.setFieldValue(fieldName, val)
                                    }}
                                    onBlur={() => {
                                        form.setFieldTouched(fieldName, true, true)
                                    }}
                                    className={`form-control ${touched && errorMessage ? 'is-invalid' : ''} ${errorMessage === false && touched ? 'is-valid' : ''}`}
                                />
                            </FloatingLabel>


                            {subText && (
                                <Form.Text className="text-muted">
                                    {subText}
                                </Form.Text>
                            )}

                            {errorMessage && (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {errorMessage}
                                </div>
                            )}
                        </Form.Group>
                    )
                }}
            </Field>
        </>
    )
}

export const phoneValidationYup = (fieldName: string, options: any = {}) => {
    const { required = false } = options

    if (!required) {
        return yup.string().test(fieldName, 'Phone number is not valid', (value: any) => {
            if(!value) return true
            return isValidPhoneNumber(value)
        })
    }

    return yup.string().required("Required").test(fieldName, 'Phone number is not valid', (value: any) => {
        if(!value) return false
        return isValidPhoneNumber(value)
    })
}