import { useMemo } from "react"
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router";
import BukinForm from "../../../Components/Forms/BukinForm/BukinForm";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { useEditBookingMutation, useEditBukinMutation, useGetBookingByIdQuery, useGetBukiblesQuery, useGetBukinByIdQuery } from "../../../services/bClientApi";
import { BukibleEntryType } from "../../../Types/bukibleTypes";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import { Link } from "react-router-dom";
import timeService from "../../../services/time/timeService";
import { convertStringToJSON } from "../../../Helpers/Strings/stringHelpers";

export default function EditBukinPage() {
  const [
    editBukin,
    {
      error: editError,
      isLoading: editIsLoading,
    }
  ] = useEditBukinMutation()

  const [
    editBooking,
    // {
    //   error: editBookingError,
    //   isLoading: editBookingIsLoading,
    // }
  ] = useEditBookingMutation()

  const { bukinId = '' } = useParams()
  const dispatch = useAppDispatch()

  const {
    data: bukiblesData,
  } = useGetBukiblesQuery({
    bukiblesQuery: {},
    pagination: {
      limit: 500,
      page: 1,
    }
  })

  const {
    data,
    isLoading,
    error,
  } = useGetBukinByIdQuery(bukinId, { skip: !bukinId })

  const bukinData = data || {}

  const {
    bookingId = ''
  } = bukinData

  const {
    data: bookingData,
    isLoading: bookingIsLoading,
    error: bookingError,
  } = useGetBookingByIdQuery(bookingId, { skip: !bookingId })

  const { myProperties } = useMyProperties()

  const bukibleSelectOptions = useMemo(() => {
    const bukiblesList = bukiblesData?.docs || []
    if (!bukiblesList) { return [] }
    return bukiblesList.map((bukible: BukibleEntryType) => ({
      value: bukible._id,
      text: bukible.title
    }))
  }, [bukiblesData])

  let foundBukin: any = {}

  if (bookingData && bookingData.linkedServices) {
    bookingData.linkedServices.forEach((linkedService: any) => {
      const linkedServicePayload: any = convertStringToJSON(linkedService.linkedServiceId)
      if (linkedServicePayload?.bukinId === bukinId) {
        foundBukin = linkedServicePayload
      }
    })
  }

  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    if (bukinId) {
      const editedBukin = await editBukin({ bukinId, bukinInfo: values }).unwrap()

      if (foundBukin && bookingId && values.status !== 'canceled') {
        const updatedBookingLinkedServices = bookingData.linkedServices.map((linkedService: any) => {
          const linkedServicePayload: any = convertStringToJSON(linkedService.linkedServiceId)
          if (linkedServicePayload?.bukinId === bukinId) {
            const updatedLinkedServiceIdPayload = {
              ...linkedServicePayload,
              bukibleId: values.bukibleId,
            }

            // BEGIN PARKING STUFF
            if (updatedLinkedServiceIdPayload.parkingStart) {
              updatedLinkedServiceIdPayload.parkingStart = new Date(values.checkIn)
            }

            if (updatedLinkedServiceIdPayload.parkingEnd) {
              updatedLinkedServiceIdPayload.parkingEnd = new Date(values.checkOut)
            }
            // END PARKING STUFF

            return {
              linkedServiceId: JSON.stringify(updatedLinkedServiceIdPayload),
              linkedService: linkedService.linkedService,
              linkedServicePrice: linkedService.linkedServicePrice
            }
          }
          return linkedService
        })
        // const editedBooking = await editBooking({ bookingId, bookingInfo: { linkedServices: updatedBookingLinkedServices } }).unwrap()
        await editBooking({ bookingId, bookingInfo: { linkedServices: updatedBookingLinkedServices } }).unwrap()
      }

      if(foundBukin && bookingId && values.status === 'canceled') {
        const updatedBookingLinkedServices = bookingData.linkedServices.filter((linkedService: any) => {
          const linkedServicePayload: any = convertStringToJSON(linkedService.linkedServiceId)
          return linkedServicePayload?.bukinId !== bukinId
        })
        // const editedBooking = await editBooking({ bookingId, bookingInfo: { linkedServices: updatedBookingLinkedServices } }).unwrap()
        await editBooking({ bookingId, bookingInfo: { linkedServices: updatedBookingLinkedServices } }).unwrap()
      }

      if (editedBukin._id) {
        dispatch(navigateTo(`/bukin/${editedBukin._id}`))
      }

      return editedBukin
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Edit Bukin</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {editError && (<>{JSON.stringify(editError)}<br /></>)}

          {bookingIsLoading && (<p>Loading booking...</p>)}
          {bookingError && (<>{JSON.stringify(bookingError)}<br /></>)}

          {bookingData && foundBukin && (
            <>
              <Row className="mb-2">
                <Col xs="12" sm="6">
                  <h4>Linked Booking</h4>
                  Name: {bookingData?.contactInfo.firstName} {bookingData?.contactInfo.lastName} <br />
                  Time: {timeService.getFormattedTime(bookingData?.checkIn)} - {timeService.getFormattedTime(bookingData?.checkOut)} <br />
                  Property: {myProperties[bookingData?.propertyId || 'nope']?.title}<br />
                  <Link to={`/booking/${bookingData._id}`}>
                    View Booking
                  </Link>
                </Col>
                <Col xs="12" sm="6">
                  {/* <pre className="small">
                  {JSON.stringify(foundBukin, null, 2)}
                </pre> */}
                  <div className="small">
                    {Object.keys(foundBukin).map((key: string) => {

                      if (key === 'bukibleId') {
                        return (
                          <div key={key}>
                            <strong>bukibleId</strong>: {bukiblesData?.docs.find((bukible: any) => bukible._id === foundBukin[key])?.title}
                          </div>
                        )
                      }
                      return (
                        <div key={key}>
                          <strong>{key}</strong>: {foundBukin[key]}
                        </div>
                      )
                    })}
                  </div>
                </Col>
              </Row>
              <hr />
            </>
          )}

          {bukinId && bukinData && !isLoading && (
            <BukinForm
              disabled={isLoading || editIsLoading}
              initialValues={bukinData}
              onSubmit={handleSubmit}
              bukibleSelectOptions={bukibleSelectOptions}
            />
          )}

        </Col>
      </Row>
    </Container>
  )
}
