import timeService from "../../services/time/timeService"
import { PropertyLinkedPropertyType, PropertyListedEntryType } from "../../Types/propertyTypes"
import { getChildProperties } from "../Property/multiUnit"

const convertBookingSource = (bookingSource: string) => {
    if (bookingSource.indexOf('airbnb') > 0) {
        return 'airbnb'
    }
    if (bookingSource.indexOf('homeaway') > 0) {
        return 'homeaway'
    }
    if (bookingSource.indexOf('booking.com') > 0) {
        return 'booking'
    }
    if(bookingSource.indexOf('vrbo') > 0) {
        return 'vrbo'
    }
    return bookingSource
}

const convertSummaryToName = (summary: string) => {
    const nameObject: any = {
        firstName: '',
        lastName: ''
    }

    const name = summary.replace('Reserved - ', '').replace('BBROS DIRECT - ', '').replace(/\([^(^)]*\)/g, '').trim().split(' ')

    if (name.length > 0) {
        nameObject.firstName = name.shift()
        nameObject.lastName = name.join(' ')
    }
    return nameObject
}

const convertSingleIcal = (iCalEntry: any) => {
    return {
        // _id: file._id,-
        checkIn: iCalEntry.checkIn ? timeService.setDateTime(iCalEntry.checkIn, { hour: 16 }) : null,
        checkOut: iCalEntry.checkOut ? timeService.setDateTime(iCalEntry.checkOut, { hour: 11 }) : null,
        propertyId: iCalEntry.propertyId,
        pricePerNight: iCalEntry.earnings ? +timeService.calculatePricePerNight(iCalEntry.earnings, iCalEntry.checkIn, iCalEntry.checkOut).toFixed(2) : 0,
        // TODO: some kind of airbnb homeaway parser - if contains either default to lowercase value and other booking listed places
        source: iCalEntry.source ? convertBookingSource(iCalEntry.source.toLowerCase()) : null,
        sourceConfirmation: iCalEntry.sourceConfirmation,
        contactInfo: {
            firstName: convertSummaryToName(iCalEntry.summary).firstName,
            lastName: convertSummaryToName(iCalEntry.summary).lastName,
            email: null,
            phone: null
        },
        dateBooked: new Date(),
        dateInquired: null,
        bookingStatus: 'new',
        cleaning: iCalEntry.cleaning,
        requiresConfirmation: false,
        confirmationCode: null,
        requiresBookingGuestInfo: false,
        bookingGuestInfoId: null,
        guestInfo: {
            guestTotal: null,
            adults: null,
            children: null,
            infants: null
        },
    }
}

export const decorateIcalEntry = (
    ical: any,
    myProperties: any,
    propertyId: any,
    existingCheck: any,
) => {
    const now = timeService.getCurrentTime().toMillis()
    const decoratedIcal = {
        ...ical,
        earnings: 0,
        cleaningFee: 0,
        viewInfoLink: '',
        sourceConfirmation: '',
        missing: false,
        blocked: false,
        past: false,
        existing: ''
    }

    if (ical.summary === 'Blocked' || ical.summary === 'Airbnb (Not available)') {
        decoratedIcal.blocked = true
        // disregard other stuff if blocked??
    }

    if (timeService.dateToMillis(ical.checkOut) < now) {
        decoratedIcal.past = true
    }

    const icalDateHash = timeService.createDateRangeHash(ical.checkIn, ical.checkOut)
    if (!existingCheck[icalDateHash]) {
        decoratedIcal.missing = true
    } else {
        decoratedIcal.existing = existingCheck[icalDateHash]
    }

    // tack on the source confirmation
    if (ical.rawData && ical.rawData.description && ical.rawData.description.indexOf('Reservation URL') > -1) {
        decoratedIcal.sourceConfirmation = ical.rawData.description.split('\n')[0].split('details/')[1]
    }

    if (ical.source === 'www.airbnb.com' && decoratedIcal.sourceConfirmation) {
        decoratedIcal.viewInfoLink = `https://www.airbnb.com/reservation/itinerary?code=${decoratedIcal.sourceConfirmation}`
    }

    if (myProperties && propertyId && myProperties[propertyId]) {
        const property = myProperties[propertyId]
        if (
            property.pricing &&
            property.pricing.cleaning
        ) {
            decoratedIcal.cleaning = property.pricing.cleaning
        }

        if (ical.source === 'www.vrbo.com' && property.listed) {
            try {
                property.listed.forEach((listed: PropertyListedEntryType) => {
                    if (listed.source === 'homeaway') {
                        // set base vrbo inbox link
                        const strippedUrl = listed.url.split('/')[3].split('?unitId=')
                        const roomNumber = strippedUrl[0]
                        const unitId = strippedUrl[1]
                        decoratedIcal.viewInfoLink = `https://www.vrbo.com/rm/321.${roomNumber}.${unitId}`
                        // search for persons name
                        const personName = ical.summary.split('Reserved - ')[1].trim()
                        if (personName) {
                            decoratedIcal.viewInfoLink += `/page/1/sort/received/desc/filter/all-messages/search/${personName}`
                        }
                    }
                })
            } catch (err) { }
        }

    }

    return decoratedIcal
}

export const convertIcalToBookings = (
    iCalEntry: any,
    myProperties: any,
) => {

    const propertyId = iCalEntry.propertyId

    const convertedEntry = convertSingleIcal(iCalEntry)

    const childBookings: any = []

    if (myProperties && propertyId && myProperties[propertyId]) {
        const property = myProperties[propertyId]
        // don't make a required confirmation for double units
        convertedEntry.requiresConfirmation = property.title.toLowerCase().indexOf('fontaine') > -1
        convertedEntry.requiresBookingGuestInfo = property?.services?.bookingGuestInfo || false
        // check property for child properties
        if (property.multiUnit && property.multiUnit.isMultiUnit && property.multiUnit.linkedUnits) {
            const childProperties: PropertyLinkedPropertyType[] = getChildProperties(myProperties, propertyId)
            if (childProperties.length >= property.multiUnit.linkedUnits.length) {
                convertedEntry.requiresConfirmation = false
            }
            childProperties.forEach((linkedProperty: PropertyLinkedPropertyType) => {
                const childProperty = myProperties[linkedProperty.propertyId]
                const childBooking = {
                    ...convertedEntry,
                    propertyId: childProperty._id,
                    pricePerNight: convertedEntry.pricePerNight ? convertedEntry.pricePerNight / childProperties.length : 0,
                    requiresConfirmation: childProperty.title.toLowerCase().indexOf('fontaine') > -1,
                    requiresBookingGuestInfo: childProperty?.services?.bookingGuestInfo || false,
                }
                childBookings.push(childBooking)
            })
        }

    }
    return [convertedEntry, ...childBookings]
}