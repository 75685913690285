import QuickForm from "../QuickForm/QuickForm"
import { MediaFileEntryType } from "../../../Types/mediaFileTypes"

const generateBlankMediaFile = () => ({
  created: new Date(),
  url: '',
  // type: 'image',
  title: '',
  description: '',
})

export default function MediaFileForm(props: {
  disabled: boolean,
  onSubmit: (v: any) => Promise<any>,
  initialValues?: Partial<MediaFileEntryType>,
  uploadImageFn?: (v: any) => Promise<string>,
}) {
  const {
    disabled,
    onSubmit,
    initialValues,
    uploadImageFn,
  } = props

  return (
    <div className="mediaFile-form">
      <QuickForm 
        initialData={{
          ...generateBlankMediaFile(),
          ...(initialValues ? initialValues : {})
        }}
        disabled={disabled}
        onSubmit={onSubmit}
        uploadImageFn={uploadImageFn}
        fields={[
            {
                fieldName: 'created',
                fieldLabel: 'Created',
                fieldType: 'date',
                placeholder: 'Created',
            },
            {
                fieldName: 'url',
                fieldLabel: 'Url',
                fieldType: 'text',
                placeholder: 'Url',
                extra: {
                  imagePreview: true,
                }
            },
            {
              fieldName: 'url',
              fieldLabel: 'Upload Image',
              fieldType: 'imageUpload',
              placeholder: 'Upload Image',
              extra: {
                accept: 'image/* audio/* video/*',
              }
            },
            // {
            //     fieldName: 'type',
            //     fieldLabel: 'Type',
            //     fieldType: 'select',
            //     placeholder: 'Type',
            //     options: [
            //       { value: 'image', text: 'Image' },
            //       { value: 'video', text: 'Video' },
            //       { value: 'audio', text: 'Audio'},
            //       { value: 'document', text: 'Document'}
            //     ]
            // },
            {
                fieldName: 'title',
                fieldLabel: 'Title',
                fieldType: 'text',
                placeholder: 'Title',
            },
            {
                fieldName: 'description',
                fieldLabel: 'Description',
                fieldType: 'text',
                placeholder: 'Description',
            },
        ]}
      />
    </div>
  )
}
