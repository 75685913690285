import { Col, Container, Row } from 'react-bootstrap'
import useMyProperties from '../../Hooks/UseMyProperties/UseMyPropertiesHook'
import { FieldGeneratorFieldType } from '../../Components/FormFields/FieldGenerator/FieldGenerator'
import { textBooleanHandler } from '../../Helpers/ValueHandlers/boolHandler'
import QuickForm from '../../Components/Forms/QuickForm/QuickForm'
import { useMemo, useState } from 'react'
import { PropertyEntry } from '../../Components/PropertyEntry/PropertyEntry'
import { Link } from 'react-router-dom'
import { accountSelector } from '../../redux/account/accountSlice'
import { useSelector } from 'react-redux'
import canAccess from '../../services/accessService/accessService'
import LoadingSpinner from '../../Components/Loader/LoadingSpinner'

export default function HomePage() {

  const { role } = useSelector(accountSelector)

  const { myProperties, isLoading } = useMyProperties()

  const [propertyFilters, setPropertyFilters] = useState({
    quickFilter: '',
    publicProperties: true,
  })

  const propertyList = useMemo(() => {
    const propertyList: any[] = []

    Object.keys(myProperties).forEach((propertyId: string) => {
      const property = myProperties[propertyId]
      if (propertyFilters.publicProperties && !property.public) {
        return
      }
      if (propertyFilters.quickFilter &&
        // !property.title.toLowerCase().includes(propertyFilters.quickFilter.toLowerCase())
        !JSON.stringify(property).toLowerCase().includes(propertyFilters.quickFilter.toLowerCase())
      ) {
        return
      }
      propertyList.push(property)
    })

    return propertyList
  }, [myProperties, propertyFilters])

  return (
    <Container>
      <Row>
        <Col>
          <h1 className={'text-capitalize'}>Welcome {role}</h1>

          <hr />

          <div className="text-center">
            {/* <Link className="btn btn-primary me-1 mb-1" to={`/all-icals`}>
              Ical Booking Converter
            </Link> */}
            {canAccess('home', 'mainLinks') && (
              <>
                <Link className="btn btn-success me-1 mb-1" to={`/upcoming-bookings`}>
                  Available & Upcoming
                </Link>
                <Link className="btn btn-warning me-1 mb-1" to={`/unconfirmed-bookings`}>
                  Unconfirmed Bookings
                </Link>
                <Link className="btn btn-info me-1 mb-1" to={`/bookings?query={"checkIn":${Date.now()}}`}>
                  Search Bookings 📕
                </Link>
                <br />
              </>
            )}

            {homeLinks.map((link, idx) => {
              if (canAccess('home', link.action)) {
                return (
                  <Link className="btn btn-dark me-1 mb-1" key={`home-link-${idx}`} to={link.link}>
                    {link.label}
                  </Link>
                )
              }
              return null
            })}

            {canAccess('home', 'link.availableUpcoming', { ignoreAdmin: true }) && (
              <Link className="btn btn-dark me-1 mb-1" to={`/upcoming-bookings`}>
                Available & Upcoming
              </Link>
            )}

            {canAccess('home', 'link.bookings', { ignoreAdmin: true }) && (
              <Link className="btn btn-dark me-1 mb-1" to={`/bookings?query={"checkIn":${Date.now()},"bookingStatus":["new","pending","confirmed"]}`}>
                Bookings 📕
              </Link>
            )}

          </div>


          {canAccess('home', 'propertyList') && (
            <>
              <hr />

              <QuickForm
                initialData={{
                  quickFilter: '',
                  publicProperties: true,
                }}
                fields={homeFormFields}
                onChange={(data: any) => {
                  const fieldName = data.target.name
                  const fieldValue = data.target.value
                  if (quickFilterFields[fieldName]) {
                    const newFilters = {
                      ...propertyFilters,
                      [fieldName]: quickFilterFields[fieldName].handler ? quickFilterFields[fieldName].handler(fieldValue) : fieldValue,
                    }
                    setPropertyFilters(newFilters)
                  }
                }} />

              {isLoading && (
                <div className="">
                  <LoadingSpinner text={'Loading Properties...'} />
                </div>
              )}

              {propertyList && propertyList.length > 0 && !myProperties.noProperties && (
                <div className="property-list-container">
                  {propertyList.map((property, idx) => (
                    <PropertyEntry
                      key={`property-${property._id}`}
                      propertyInfo={property}
                    />
                  ))}
                </div>
              )}
            </>
          )}

        </Col>
      </Row>
    </Container>
  )
}

const quickFilterFields: any = {
  quickFilter: 1,
  publicProperties: {
    handler: textBooleanHandler
  },
}

const homeFormFields: FieldGeneratorFieldType[] = [
  {
    fieldName: 'quickFilter',
    fieldLabel: 'Quick Filter',
    fieldType: 'text',
  },
  {
    fieldName: 'publicProperties',
    fieldLabel: 'Public Properties',
    fieldType: 'checkbox',
    required: false
  }
]

const homeLinks = [
  { link: '/adr-occupancy-reporter', label: 'Reports 📈', action: 'link.reports' },
  { link: '/cleaning', label: 'Cleaning 🧹', action: 'link.cleaning' },
  { link: '/property-task-list', label: 'Tasks 📝', action: 'link.tasks' },
  { link: '/bukibles', label: 'Bukibles 📖', action: 'link.bukibles' },
  { link: '/confirm-booking-guests', label: 'Guests 💁', action: 'link.guests' },
  { link: '/booking-messages', label: 'Messages 💬', action: 'link.bookingMessages' },
]