import { Button, Card } from "react-bootstrap"
import { formatAddress, googleAddress } from "../../Helpers/Property/propertyAddressFormatters"
import './propertyEntry.scss'
import timeService from "../../services/time/timeService"
import AccessLinkButton from "../Buttons/AccessLinkButton"
import { PropertyType } from "../../Types/propertyTypes"
import { API_URL, FRONTEND_URL } from "../../Config/config"
import canAccess from "../../services/accessService/accessService"
import { useAppDispatch } from "../../redux/store"
import { useHandleDoorLockStateMutation } from "../../services/bClientApi"
import { showError, showSuccess } from "../../redux/counter/counterSlice"

export const PropertyEntry = (props: any) => {
  const {
    propertyInfo,
    details = false,
    disableLinks = {}
  } = props

  const {
    _id,
    title,
    address,
  } = propertyInfo

  const startOfToday = timeService.createLuxonDate(new Date()).startOf('day').toMillis()

  return (
    <Card className="property-entry">
      {/* <Card.Header>
        <b>{title}</b>
      </Card.Header> */}
      <Card.Body>
        <Card.Title>
          <b>{title}</b>
        </Card.Title>
        {address && (
          <Card.Link
            className={`small text-decoration-none`}
            href={`${googleAddress(address)}`}
            target="_blank">
            {formatAddress(address)}
          </Card.Link>
        )}

        {details && (
          <PropertyInfo {...propertyInfo} />
        )}


      </Card.Body>
      <Card.Footer>
        {!disableLinks['view'] && (
          <AccessLinkButton
            action={'property'}
            text={'View'}
            size={'sm'}
            to={`/property/${_id}`}
          />
        )}

        <AccessLinkButton
          action={'ical-data'}
          color={'success'}
          size={'sm'}
          text={'Ical'}
          to={`/property/ical-data/${_id}`}
        />

        <AccessLinkButton
          action={'financials-property'}
          color={'success'}
          size={'sm'}
          text={'Financials'}
          to={`/financials-property/${_id}`}
        />

        {/* handle property prices link */}
        <AccessLinkButton
          action={'handle-property-prices'}
          color={'success'}
          size={'sm'}
          text={'Pricing'}
          to={`/handle-property-prices/${_id}`}
        />

        <AccessLinkButton
          action={'property-inventory'}
          color={'secondary'}
          size={'sm'}
          text={'Inventory'}
          to={`/inventory-check/${_id}`}
        />

        <AccessLinkButton
          action={'property-bookings'}
          color={'secondary'}
          size={'sm'}
          text={'Bookings'}
          to={`/bookings?query={"propertyId":"${_id}","checkIn":${startOfToday},"bookingStatus":["new","pending","confirmed"]}`}
        />

        <AccessLinkButton
          action={'property-tasks'}
          color={'secondary'}
          size={'sm'}
          text={'Tasks'}
          to={`/property-tasks?query={"propertyId":"${_id}"}`}
        />

        {/* add booking link */}
        <AccessLinkButton
          action={'add-booking'}
          color={'dark'}
          size={'sm'}
          text={'Booking⚡'}
          to={`/add-booking?query={"propertyId":"${_id}", "quickBooking":1}`}
        />

        <AccessLinkButton
          action={'full-add-booking'}
          color={'dark'}
          size={'sm'}
          text={'+ Booking'}
          to={`/add-booking?query={"propertyId":"${_id}"}`}
        />

        {/* add task link */}
        <AccessLinkButton
          action={'add-task'}
          color={'dark'}
          size={'sm'}
          text={'+ Task'}
          to={`/add-property-task?query={"propertyId":"${_id}"}`}
        />

        <AccessLinkButton
          action={'date-blocks'}
          color={'warning'}
          size={'sm'}
          text={'Blocks'}
          to={`/date-blocks?query={"propertyId":"${_id}", "checkIn":${startOfToday}}`}
        />

        <AccessLinkButton
          action={'edit-property'}
          color={'secondary'}
          size={'sm'}
          text={'Edit'}
          to={`/edit-property/${_id}`}
        />

        {/* <AccessLinkButton
          action={'delete-property'}
          color={'danger'}
          size={'sm'}
          text={'Delete'}
          to={`/delete-property/${_id}`}
        /> */}

      </Card.Footer>
    </Card>
  )
}


export const PropertyInfo = (props: PropertyType) => {
  const { _id, created, title, description, featuredImage, multiUnit, listed, linkedServices } = props

  const [
    handleDoorState,
    {
      isLoading: handleDoorStateLoading,
    }
  ] = useHandleDoorLockStateMutation()

  let remoteLockPayload: {
    deviceId?: string,
    deviceName?: string,
    deviceType?: string,
  } = {}

  if (linkedServices && linkedServices.length > 0) {
    const remoteLock = linkedServices.find((service: any) => service.linkedService === 'remoteLock')
    if (remoteLock) {
      remoteLockPayload = JSON.parse(remoteLock.linkedServiceId)
      if (!remoteLockPayload.deviceType) {
        remoteLockPayload.deviceType = 'locks'
      }
    }
  }

  const dispatch = useAppDispatch()

  const handleRemoteLockDoorStateRequest = async (action: 'lock' | 'unlock') => {
    const doorId = remoteLockPayload.deviceId
    if (!doorId) {
      return
    }
    const doorResponse = await handleDoorState({
      doorId,
      action,
    }).unwrap()

    console.log('Door Response:', doorResponse)

    if (doorResponse.doorStateResult) {
      dispatch(showSuccess('Door Lock Updated'))
      return
    }
    dispatch(showError('Door Lock Update Failed'))
  }

  return (
    <div>
      <hr />

      {/* <h2>{title}</h2> */}

      <p className="fst-italic text-break">
        {description}
      </p>


      Property ID: {_id}
      {created && (<>
        <br />
        Created: {timeService.getFormattedTime(new Date(created))}
      </>)}
      <br />
      Web Link: &nbsp;
      <a target="_blank" href={`${FRONTEND_URL}/property/${_id}`} rel="noreferrer">
        {`${FRONTEND_URL}/property/${_id}`}
      </a>

      <br /><br />

      <PropertyListedList listed={listed} className="mb-4" />

      {remoteLockPayload.deviceId && (
        <div>
          <b>Remote Lock</b> <br />
          <div className="mb-4">
            <div>
              Device ID: <a target="_blank" href={`https://connect.remotelock.com/devices/${remoteLockPayload.deviceType}/${remoteLockPayload.deviceId}`} rel="noreferrer">
                {remoteLockPayload.deviceId}
              </a>
            </div>
            <div>
              Device Name: {remoteLockPayload.deviceName}
            </div>
            <div>
              Device Type: {remoteLockPayload.deviceType}
            </div>
            {canAccess('property', 'door-controls') && (
              <div>
                <Button
                  onClick={() => handleRemoteLockDoorStateRequest('lock')}
                  disabled={handleDoorStateLoading}
                  className={'me-2 mb-2'}
                  variant={'danger'}
                  size={'sm'}
                >
                  Lock
                </Button>
                <Button
                  onClick={() => handleRemoteLockDoorStateRequest('unlock')}
                  disabled={handleDoorStateLoading}
                  className={'me-2 mb-2'}
                  variant={'success'}
                  size={'sm'}
                >
                  Unlock
                </Button>
              </div>
            )}
          </div>
        </div>
      )}

      <b>Icalendars</b>
      <br />
      Ical Link: &nbsp;
      <a target="_blank" href={`${API_URL}/icalendar/property/${_id}.ics`} rel="noreferrer">
        {`${API_URL}/icalendar/property/${_id}.ics`}
      </a>
      <br />

      {multiUnit?.isMultiUnit && (
        <>
          MULTI Ical Link: &nbsp;
          <a target="_blank" href={`${API_URL}/icalendar/property-multi/${_id}.ics`} rel="noreferrer">
            {`${API_URL}/icalendar/property-multi/${_id}.ics`}
          </a>
          <br />
        </>
      )}

      <br />
      <img className="property-img" src={featuredImage} alt={title} />
      <br />
    </div>
  )
}

export const PropertyListedList = (props: {
  listed: {
    source: string,
    url: string
  }[]
  className?: string
}) => {
  const { 
    listed,
    className
  } = props

  if(!listed || listed.length === 0) {
    return null
  }

  return (
    <div className={className}>
      <b>Online Listings</b>
      <div>
        {listed.map((listing, i) => {

          let editLink = ''
          let calendarLink: string = ''

          if (listing.source === 'airbnb' && canAccess('property', 'edit-airbnb')) {
            calendarLink = listing.url.replace('rooms', 'multicalendar')
            const airbnbId = listing.url.split('/').pop()
            editLink = `https://www.airbnb.com/hosting/listings/editor/${airbnbId}`
          }

          return (
            <div key={i}>
              {listing.source}:  <a target="_blank" href={listing.url} rel="noreferrer">
                {listing.url}
              </a>&nbsp;
              {editLink && (
                <a className="text-decoration-none" target="_blank" href={editLink} rel="noreferrer" title="Edit Listing">
                  ✏️
                </a>
              )}
              {calendarLink && (
                <>
                  &nbsp;
                  <a target="_blank" href={calendarLink} rel="noreferrer">
                    📅
                  </a>
                </>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}